import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../shared/services/shared.service";
import {StatsService} from "../../shared/services/stats.service";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {Evaluation} from "../../shared/models/Evaluation";
import {Service} from "../../shared/models/Service";
import {Niveau} from "../../shared/models/Niveau";

declare let jQuery: any;

@Component({
  selector: 'app-evaluations-etudiants',
  templateUrl: './evaluations-etudiants.component.html',
  styleUrls: ['./evaluations-etudiants.component.css']
})

export class EvaluationsEtudiantsComponent implements OnInit {
  evaluations: Evaluation[];
  service_id: number;
  niveau_id: number;
  niveau: Niveau;
  service: Service;
  loaded=false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private statsService: StatsService,
              private evaluationServices: EvaluationService,
              private sessionStageService: SessionStageService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {

        this.service_id = params.service_id;
        this.niveau_id = params.niveau_id;
        if (this.service_id) {
          this.getServiceById(this.service_id);
        }
        if (this.niveau_id) {
          this.getNiveauById(this.niveau_id);
        }
      });
    this.getCommentsByServiceAndLevel(this.service_id, this.niveau_id);
  }

  getCommentsByServiceAndLevel(service_id, niveau_id) {
    this.statsService.getCommentsByServiceAndLevel(service_id, niveau_id).subscribe((data: Evaluation[]) => {
      jQuery('.modal_comments').modal();
      this.evaluations = data;
      this.loaded = true;
    });

  }

  getServiceById(service_id: number) {
    if (service_id) {
      this.sharedService.getServiceById(service_id).subscribe((data: Service) => {
        this.service = data;
      })
      ;
    }
  }

  getNiveauById(niveau_id: number) {
    if (niveau_id) {
      this.sharedService.getNiveauById(niveau_id).subscribe((data: Niveau) => {
        this.niveau = data;
      })
      ;
    }
  }

  goBack() {
    this.router.navigate(['/statistiques-services/global'])
  }
}
