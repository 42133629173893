import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {Critere} from "../models/Critere";
import {Evaluation} from "../models/Evaluation";
import {environment} from "../../../environments/environment";

@Injectable()
export class EvaluationService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getAllCriteres() {
    const url = environment.baseUrl + "/public/critere/all";


    return this.http.get(url);
  }

  getAllCriteresEtudiants() {
    const url = environment.baseUrl + "/public/criteres_etudiants";


    return this.http.get(url);
  }

  changePonderationCriteres(levelId: number, criteres: Critere[]) {
    const url = environment.baseUrl + "/public/critere-level/" + levelId + "/edit-pond";


    return this.http.put(url, criteres);
  }

  getAllEvaluationBySessionStageId(sessionStageId: number) {
    const url = environment.baseUrl + "/evaluation/" + sessionStageId;


    return this.http.get(url);
  }

  getEvaluationsByCriterias(sessionStageId: number, serviceId: number, periodeId: number, periodeStageId: number) {
    const url = environment.baseUrl + "/evaluation/" + sessionStageId;


    return this.http.post(url, {
      serviceId: serviceId,
      periodeId: periodeId,
      periodeStageId: periodeStageId
    });
  }

  changeCoordinatorForStudent(evaluationId, enseignantId, levelId) {
    const url = environment.baseUrl + "/evaluation/" + evaluationId + "/change-coordinator-student";


    return this.http.put(url, {
      enseignantId: enseignantId,
      levelId: levelId
    });
  }

  changeServiceForStudent(selectedEvaluation: Evaluation, newServiceId: number) {
    const url = environment.baseUrl + "/evaluation/change-service-student";


    return this.http.put(url, {
      evaluation: selectedEvaluation,
      serviceId: newServiceId,
    });
  }


  modifyEcosNote(result_groupe_id: number, note_ecos: number) {
    const url = environment.baseUrl + "/evaluation/change-note-ecos/" + result_groupe_id;

    return this.http.put(url, {
      note_ecos: note_ecos
    });
  }

  getEvaluationById(evaluationId: number) {
    const url = environment.baseUrl + "/public/evaluation/" + evaluationId;
    return this.http.get<any>(url);
  }


  editEvaluationStage(evaluation: Evaluation, criteres: Critere[]) {
    const url = environment.baseUrl + "/public/evaluation/edit";
    return this.http.put<any>(url, {evaluation: evaluation, criteres: criteres});
  }

  submitDefaultNotes(periode_stage_id: number) {
    const url = environment.baseUrl + "/evaluation/submit-default-note/" + periode_stage_id;
    return this.http.get(url);
  }
}
