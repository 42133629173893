import {NgModule} from '@angular/core';
import {AddSessionStageComponent} from './add-session-stage/add-session-stage.component';
import {SharedModule} from "../shared/shared.module";
import {SessionStageRouting} from "./session-stage.routing";
import { ListSessionStageComponent } from './list-session-stage/list-session-stage.component';
import { ListAffectationComponent } from './list-affectation/list-affectation.component';
import { TableauBoardComponent } from './tableau-board/tableau-board.component';
import { AddAffectationComponent } from './add-affectation/add-affectation.component';
import { EditEvaluationComponent } from './edit-evaluation/edit-evaluation.component';
import { AffectSummerComponent } from './affect-summer/affect-summer.component';
import { EditEcosComponent } from './edit-ecos/edit-ecos.component';
import { EditPortfolioComponent } from './edit-portfolio/edit-portfolio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    SessionStageRouting,
    SharedModule,
    FormsModule,
  ReactiveFormsModule
  ],
  declarations: [
    AddSessionStageComponent,
    ListSessionStageComponent,
    ListAffectationComponent,
    TableauBoardComponent,
    AddAffectationComponent,
    EditEvaluationComponent,
    AffectSummerComponent,
    EditEcosComponent,
    EditPortfolioComponent
  ]
})
export class SessionStageModule {
}
