import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {Session_Stage} from "../models/Session_Stage";
import {AffectationRequest} from "../models/AffectationRequest";
import {ResultInterne} from "../models/ResultInterne";
import {FilterResult, ResultGroupe} from "../models/ResultGroupe";
import {environment} from "../../../environments/environment";

@Injectable()
export class SessionStageService extends GenericService {
  context: any;

  filterResult: FilterResult = new FilterResult();


  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  addSessionStage(sessionStage: Session_Stage) {
    const url = environment.baseUrl + '/session-stage/add';


    return this.http.post(url, sessionStage);
  }

  getAllSessionStage() {
    const url = environment.baseUrl + '/session-stage';


    return this.http.get(url);
  }

  getSessionStageById(sessionStageId: number) {
    const url = environment.baseUrl + '/session-stage/' + sessionStageId;


    return this.http.get(url);
  }

  delete(sessionStageId: number) {
    const url = environment.baseUrl + '/session-stage/' + sessionStageId + '/delete';


    return this.http.delete(url);
  }

  editSessionStage(sessionStageId: number, session: Session_Stage) {
    const url = environment.baseUrl + '/session-stage/' + sessionStageId + '/edit';


    return this.http.put(url, session);
  }

  delibrationGroupStage(session_stage_id: number) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/delibration-group';


    return this.http.get(url);
  }

  delibrationServiceStage(session_stage_id: number) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/delibration-service';


    return this.http.get(url);
  }

  generateResultGroupPDF(session_stage_id: number) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/generate-choice-group-result';


    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  generateResultServicePDF(session_stage_id: number) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/generate-choice-service-result';


    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  getPeriodesGroups(session_stage_id: number) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/periodes-groups';


    return this.http.get(url);
  }

  getStudentsByPeriodeSGroupe(session_stage_id, periode_id, sgroupe_id) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/students-periode-sgroupes';


    return this.http.post(url, {
      periode_id: periode_id,
      sgroupe_id: sgroupe_id
    });
  }

  affectEtudiants(session_stage_id  , letter) {
    const url = environment.baseUrl + '/session-stage/' + session_stage_id + '/affect-students-stage/' + letter;
    return this.http.get(url);
  }

  getStudentsNotAffected(sessionStageId: number) {
    const url = environment.baseUrl + "/session-stage/" + sessionStageId + "/students-not-affected";

    return this.http.get(url);
  }

  addAffectation(sessionStageId, affectation: AffectationRequest) {
    const url = environment.baseUrl + "/session-stage/" + sessionStageId + "/affect-student";

    return this.http.post(url, affectation);
  }

  getGroupesOfJury(id_teacher) {
    const url = environment.baseUrl + "/shared/groupes/byJury/" + id_teacher;
    return this.http.get<any>(url);
  }

  getStudentsByGroupe(groupe_id: number) {
    const url = environment.baseUrl + "/public/session-stage/students/byGroupe/" + groupe_id;
    return this.http.get<any>(url);
  }

  getStudentsByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const url = environment.baseUrl + "/public/session-stage/students/byGroupeAndPeriode/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url);
  }

  getNumberStudentsByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const url = environment.baseUrl + "/public/session-stage/students/numberByGroupeAndPeriode/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url);
  }

  getResultGroupesByGroupe(groupe_id: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/byGroupe/" + groupe_id;
    return this.http.get<any>(url);
  }

  getResultGroupesByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/byGroupeAndPeriodeAdmin/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url);
  }

  getResultGroupesByStudentByIndexInGroupe(groupe_id: number, student_index: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/byStudentIndexInGroupe/" + groupe_id + "/" + student_index;
    return this.http.get<any>(url);
  }

  getResultGroupesByStudentByIdAndGroupe(groupe_id: number, student_id: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/byStudentIdAndGroupe/" + groupe_id + "/" + student_id;
    return this.http.get<any>(url);
  }

  submitDecisionJury(result_groupe: ResultGroupe) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/submitDecision";
    return this.http.post<any>(url, result_groupe);
  }

  ignoreDecisionResultGroupe(result_groupe_id: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/ignoreDecisionResultGroupe/" + result_groupe_id;
    return this.http.delete<any>(url);
  }

  ignoreDecisionGroupePeriode(groupe_id: number, periode_id: number) {
    const url = environment.baseUrl + "/public/session-stage/resultGroupes/ignoreDecisionGroupePeriode/" + groupe_id + "/" + periode_id;
    return this.http.delete<any>(url);
  }

  decideResultInterne(result_interne: ResultInterne) {
    const url = environment.baseUrl + "/session-stage/" + result_interne.session_stage_id + "/deliberation-internes/decideResult";
    return this.http.post<any>(url, result_interne);
  }

  editResultInterne(result_interne: ResultInterne) {
    const url = environment.baseUrl + "/session-stage/" + result_interne.session_stage_id + "/deliberation-internes/editResult";
    return this.http.put<any>(url, result_interne);
  }

  deliberationAutomatique(session_stage_id: number) {
    const url = environment.baseUrl + "/session-stage/" + session_stage_id + "/deliberation-internes/deliberationAutomatique";
    return this.http.put<any>(url, session_stage_id);
  }

  isEmptyFilter() {
    return !this.filterResult.groupId || !this.filterResult.periodeId;
  }

  settingFilter(selectedGroupeId: number, selectedPeriodeId: any) {
    this.filterResult.periodeId = selectedPeriodeId;
    this.filterResult.groupId = selectedGroupeId;
  }

  getResultGroupesByPeriode(periode_id: number) {
    const url = environment.baseUrl + "/result-group/" + periode_id;
    return this.http.get<any>(url);

  }
}
