import {Groupe} from "./Groupe";
import {Periode} from "./Periode";
import {Niveau} from "./Niveau";

export class Session_Stage {
  public session_stage_id: number;
  public periodes: Periode[] = [];
  public nb_p_g: string;
  public groupes: Groupe[] = [];
  public niveau: Niveau = new Niveau();
  public niveau_id: number;
  public duration_periode: number;
  public name: string;
  public date_choice_open: string;
  public date_choice_close: string;
  public date_service_open: string;
  public date_service_close: string;
  public capacity: number;

  public status_group_choice: number;
  public status_service_choice: number;
  public status_affectation: number;


}
