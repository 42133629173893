import {Injectable} from '@angular/core';
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StatsService extends GenericService {


  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getAllStatsServices() {
    const url = environment.baseUrl + '/public/stats/services/getAll';


    return this.http.get(url);
  }

  getAllNiveauxHavingStats() {
    const url = environment.baseUrl + '/public/stats/niveaux/withStats';

    return this.http.get(url);
  }


  getAllServicesHavingStats() {
    const url = environment.baseUrl + '/public/stats/services/withStats';

    return this.http.get(url);
  }

  refreshAllStats() {
    const url = environment.baseUrl + '/public/stats/services/refresh';

    return this.http.get(url);
  }

  getNumbers() {
    const url = environment.baseUrl + '/public/stats/services/getNumbers';

    return this.http.get(url);
  }

  getCommentsByServiceAndLevel(service_id, niveau_id) {
    const url = environment.baseUrl + '/public/stats/services/commentsByServiceAndLevel';
    if (!service_id && !niveau_id)
      return this.http.get(url, {
          params: {}
        }
      );
    if (service_id && !niveau_id)
      return this.http.get(url, {
          params: {
            service_id: service_id
          }
        }
      );
    if (!service_id && niveau_id)
      return this.http.get(url, {
          params: {
            niveau_id: niveau_id
          }
        }
      );
    if (service_id && niveau_id)
      return this.http.get(url, {
          params: {
            service_id: service_id,
            niveau_id: niveau_id
          }
        }
      );

  }
}

