import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ManageResultatsInternesRouting} from "./manage-resultats-internes.routing";
import {FullLayoutComponent} from "../layouts/full-layout.component";
import { DeliberationResultatsInternesComponent } from './deliberation-resultats-internes/deliberation-resultats-internes.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ManageResultatsInternesRouting
  ],
  declarations: [
    DeliberationResultatsInternesComponent]
})
export class ManageResultatsInternesModule { }
