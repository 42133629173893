import {Injectable} from '@angular/core';
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PeriodeService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  getAllStagePeriodes(sessionStageId: number) {
    const url = environment.baseUrl + "/session-stage/" + sessionStageId + "/stageperiodes";
    return this.http.get(url);
  }

  getAllPeriodeBySessionStageId(sessionStageId: number) {
    const url = environment.baseUrl + "/session-stage/" + sessionStageId + "/periodes";
    return this.http.get(url);
  }

}
