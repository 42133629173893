import {Injectable} from '@angular/core';
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {CoordinatorRequest} from "../models/Coordinator";
import {Service} from "../models/Service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends GenericService {

  serviceListener: ServiceListener;

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getAll() {
    const url = environment.baseUrl + '/service';


    return this.http.get(url);
  }

  affectCoordiateursToService(coordinator: CoordinatorRequest) {
    const url = environment.baseUrl + '/service/' + coordinator.service_id + '/affect-coordinators';


    return this.http.post(url, {
      niveau_id: coordinator.niveau_id,
      enseignantIds: coordinator.enseignantIds
    });
  }

  changeServices(services: Service[]) {
    if (this.serviceListener != null) {
      this.serviceListener.onChangeService(services);
    }
  }

  automaticAffectationByService(service_id: number, niveau_id: number) {
    const url = environment.baseUrl + '/service/' + service_id + '/affect-auto-coordinators';


    return this.http.post(url, {
      levelId: niveau_id
    });
  }
}

export interface ServiceListener {
  onChangeService(services: Service[]): void;
}
