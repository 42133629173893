/**
 * Created by Abbes on 13/06/2017.
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Layouts
import {FullLayoutComponent} from './layouts/full-layout.component';
import {LoginComponent} from "./login/login.component";
import {ManageEvaluationModule} from "./manage-evaluation/manage-evaluation.module";
import {SessionStageModule} from "./session-stage/session-stage.module";
import {ManageCoordinatorModule} from "./manage-coordinator/manage-coordinator.module";
import {ManageJuryModule} from "./manage-jury/manage-jury.module";
import {ManageResultatsInternesModule} from "./manage-resultats-internes/manage-resultats-internes.module";
import {StatistiquesServicesModule} from "./statistiques-evaluations/statistiques-services.module";
import {ManageStudentsModule} from "./manage-students/manage-students.module";

export function loadSessionStageModule() {
  return SessionStageModule
}

export function loadManageEvaluationModule() {
  return ManageEvaluationModule
}

export function loadManageCoordinatorModule() {
  return ManageCoordinatorModule
}

export function loadManageJuryModule() {
  return ManageJuryModule
}

export function loadManageResultatsInternesModule() {
  return ManageResultatsInternesModule
}

export function loadStatistiquesServicesModule() {
  return StatistiquesServicesModule
}

export function loadManageStudents() {
  return ManageStudentsModule
}

export const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'session-stage',
        loadChildren: loadSessionStageModule
      },
      {
        path: 'manage-evaluation',
        loadChildren: loadManageEvaluationModule
      },
      {
        path: 'manage-coordinator',
        loadChildren: loadManageCoordinatorModule
      },
      {
        path: 'manage-jury',
        loadChildren: loadManageJuryModule
      },
      {
        path: 'manage-resultats-internes',
        loadChildren: loadManageResultatsInternesModule
      },
      {
        path: 'statistiques-services',
        loadChildren: loadStatistiquesServicesModule
      },
      {
        path: 'manage-student',
        loadChildren: loadManageStudents
      }

    ],
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
