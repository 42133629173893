import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {Evaluation} from "../../shared/models/Evaluation";
import {Subscription} from "rxjs";
import {CriterePortfolio} from "../../shared/models/CriterePortfolio";
import {PortfolioService} from "../../shared/services/portfolio.service";

declare let swal: any;
declare let jQuery: any;

@Component({
  selector: 'app-edit-portfolio',
  templateUrl: './edit-portfolio.component.html',
  styleUrls: ['./edit-portfolio.component.css']
})
export class EditPortfolioComponent implements OnInit {

  evaluationId: number;
  sessionStageId: number;
  evaluation: Evaluation = new Evaluation();
  busy: Subscription;

  criteres: CriterePortfolio[];

  globalEvaluation: number;

  validations: any[] = [
    {
      label: 'Oui',
      value: 1
    },
    {
      label: 'Non',
      value: 0
    }
  ];


  constructor(private route: ActivatedRoute,
              private router: Router,
              private evaluationService: EvaluationService,
              private portfolioService: PortfolioService) {
  }

  ngOnInit() {
    this.evaluationId = parseInt(this.route.snapshot.paramMap.get('evaluationId'), 10);
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'), 10);
    if (!this.evaluationId || !this.sessionStageId) {
      this.router.navigateByUrl('/session-stage/list');
    }

    this.getEvaluation();
  }

  getEvaluation() {
    this.busy = this.evaluationService.getEvaluationById(this.evaluationId)
      .subscribe(
        (data) => {
          this.evaluation = data;
          if (!this.evaluation.evaluation_portfolio) {
            this.router.navigateByUrl('/session-stage/list');
            return;
          }
          this.globalEvaluation = this.evaluation.evaluation_portfolio.validation;
          this.getAllCriteres();
        },
        (error) => {

        }
      )
  }

  getAllCriteres() {
    this.busy = this.portfolioService.getAllPortfolioCriteres()
      .subscribe(
        (data) => {
          this.criteres = data;
          this.affectCriteresToEvaluation();
        },
        (error) => {

        }
      )
  }

  affectCriteresToEvaluation() {
    console.log(this.evaluation.evaluation_portfolio);

    const validations = this.evaluation.evaluation_portfolio.validations;
    this.criteres.forEach(
      critere => {
        let index = validations.map(item => {
          return item.critere_portfolio_id;
        }).indexOf(critere.critere_portfolio_id);
        critere.validation = validations[index].validation;
        critere.document_number = validations[index].document_number;
      }
    )
    console.log(this.criteres);
  }

  isEmptyChamps() {
    for (let i = 0; i < this.criteres.length; i++) {
      if ((this.criteres[i].has_document && this.criteres[i].document_number < 0)
        || this.criteres[i].validation === undefined) {
        return true;
      }
    }
    return false;
  }

  submitEvaluation() {

    if (!this.globalEvaluation || this.isEmptyChamps()) {
      swal('Warning', 'Vérifier que les champs sont remplis', 'warning');
      return;
    }

    const baseContext = this;
    swal({
      title: 'Vous etes sur?',
      text: "Confirmation d'evaluation de ce stage!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }).then(isConfirm => {
      if (isConfirm) {
        baseContext.busy = this.portfolioService
          .editEvaluationPortfolio(this.evaluationId, this.evaluation.etudiant_id, this.globalEvaluation, this.criteres)
          .subscribe(
            (data) => {
              swal("Succées", "Evaluation faite avec succées", "success");
              baseContext.router.navigateByUrl('/session-stage/' + baseContext.sessionStageId + '/tableau-board');
            },
            (error) => {

            }
          );
      }
    })
  }

}
