import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddJuryComponent } from './add-jury/add-jury.component';
import {ManageJuryRouting} from "./manage-jury.routing";
import {SharedModule} from "../shared/shared.module";
import { ListResultsComponent } from './list-results/list-results.component';
import { DetailsEvaluationJuryComponent } from './details-evaluation-jury/details-evaluation-jury.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ManageJuryRouting

  ],
  declarations: [AddJuryComponent, AddJuryComponent, ListResultsComponent, DetailsEvaluationJuryComponent]
})
export class ManageJuryModule { }
