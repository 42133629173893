import {Session_Stage} from "./Session_Stage";

export class Periode {
  public periode_id: number;
  public start_date: string; // X S S S
  public end_date: string; // S S S X

  public label: string = "Periode";
  public no_speriodes: number = 1;
  public session_stage: Session_Stage = new Session_Stage();
  public contraintes: Contrainte[] = [];
}

export class Contrainte {
  public start_date: string;
  public end_date: string;
  public periode_id: number;
}
