import {Periode} from "./models/Periode";

declare var jQuery: any;

export class Utils {


  public static getElementFromKey(array, key, value) {
    return array[array.map(
      item => {
        return item[key];
      }
    ).indexOf(value)];
  }

  public static zero(n: number) {
    if (n < 10) {
      return "0" + n;
    }
    return n;
  }

  static configDataTables() {
    jQuery.extend(jQuery.fn.dataTable.defaults, {
      autoWidth: false,
      columnDefs: [{
        orderable: false,
        width: '100px',
        targets: [5]
      }],
      dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
      language: {
        search: '<span>Filter:</span> _INPUT_',
        lengthMenu: '<span>Show:</span> _MENU_',
        paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
      },
      drawCallback: function () {
        jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
      },
      preDrawCallback: function () {
        jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
      }
    });
  }

  static getRandomString() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static initializeDataTables(className, timout: number, columnNumber?: number, pageLength?: number, orderBy ?: number) {
    // Basic datatable
    if (jQuery.fn.DataTable.isDataTable('.' + className)) {
      jQuery('.' + className).DataTable().destroy();
    }
    setTimeout(function () {
        const tableListStation = jQuery('.' + className);
        // Setting datatable defaults
        jQuery.extend(jQuery.fn.dataTable.defaults, {
          pageLength: pageLength,
          autoWidth: false,
          columnDefs: [{
            orderable: false,
            width: '100px',
            targets: [columnNumber - 1]
          }],
          dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
          language: {
            search: '<span>Filter:</span> _INPUT_',
            lengthMenu: '<span>Show:</span> _MENU_',
            paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
          },
          drawCallback: function () {
            jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
          },
          preDrawCallback: function () {
            jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
          }
        });


        // Basic datatable
        tableListStation.DataTable({
          dom: 'Bfrtip',
          language: {
            "emptyTable": "..."
          },
          buttons: {
            dom: {
              button: {
                className: 'btn btn-default'
              }
            },
            buttons: [
              'excelHtml5',
              'pdfHtml5'
            ]
          }
          ,
          columnDefs: [{
            targets: [columnNumber - 1]
          }]
        });


        // Add placeholder to the datatable filter option
        jQuery('.dataTables_filter input[type=search]').attr('placeholder', 'Type to filter...');


        // Enable Select2 select for the length option
        jQuery('.dataTables_length select').select2({
          minimumResultsForSearch: Infinity,
          width: 'auto'
        });
      }
      , timout);


  }


  public static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  static convertDate(date: string) {
    if (date) {
      return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4);
    }
    return null;
  }

  public static groupByM(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  static initializeNavigation(className: string) {
    // ========================================
    //
    // Main navigation
    //
    // ========================================


    // Main navigation
    // -------------------------

    // Add 'active' class to parent list item in all levels
    jQuery('.navigation').find('li.active').parents('li').addClass('active');

    // Hide all nested lists
    jQuery('.navigation').find('li').not('.active, .category-title').has('ul').children('ul').addClass('hidden-ul');

    // Highlight children links
    jQuery('.navigation').find('li').has('ul').children('a').addClass('has-ul');

    // Add active state to all dropdown parent levels
    jQuery('.dropdown-menu:not(.dropdown-content), .dropdown-menu:not(.dropdown-content) .dropdown-submenu')
      .has('li.active').addClass('active').parents('.navbar-nav .dropdown:not(.language-switch), ' +
      '.navbar-nav .dropup:not(.language-switch)').addClass('active');


    // Main navigation tooltips positioning
    // -------------------------

    // Left sidebar
    jQuery('.' + className + ' > .navigation-header > i').tooltip({
      placement: 'right',
      container: 'body'
    });


    // Collapsible functionality
    // -------------------------

    // Main navigation
    jQuery('.' + className).find('li').has('ul').children('a').on('click', function (e) {
      e.preventDefault();

      // Collapsible
      jQuery(this).parent('li').not('.disabled').not(jQuery('.sidebar-xs')
        .not('.sidebar-xs-indicator').find('.' + className).children('li')).toggleClass('active').children('ul').slideToggle(250);

      // Accordion
      if (jQuery('.' + className).hasClass('navigation-accordion')) {
        jQuery(this).parent('li').not('.disabled').not(jQuery('.sidebar-xs')
          .not('.sidebar-xs-indicator').find('.' + className).children('li'))
          .siblings(':has(.has-ul)').removeClass('active').children('ul').slideUp(250);
      }
    });

  }

  static initSelect(className: string) {
    const select = jQuery('.' + className);
    select.select2();
  }

  static setValuesjQueryCmp(className: string, values: any, timout: number) {

    if (values) {
      setTimeout(function () {
        const component = jQuery('.' + className);
        component.val(values).trigger('change');
      }, timout);

    }
  }

  static setValueToRadioBox(className: string, value: any, timout: number) {
    const radioBox = jQuery('.' + className).prop('checked', false);
    jQuery.uniform.update(radioBox);
    if (value !== undefined) {
      setTimeout(function () {
        const radioBox = jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
        jQuery.uniform.update(radioBox);
      }, timout);
    }
  }

  static setValueToCheckBox(className: string, value: any) {
    const checkBox = jQuery('.' + className);
    checkBox.prop('checked', value);
    jQuery.uniform.update(checkBox);
  }


  static getCurrentPeriodeId(periodes: Periode[]) {

    for (let i = 0; i < periodes.length; i++) {
      const startDate = Utils.convertRealDate(periodes[i].start_date);
      const endDate = Utils.convertRealDate(periodes[i].end_date);
      if (startDate <= new Date() && endDate >= new Date()) {
        return periodes[i].periode_id;
      }
    }
    return periodes[0].periode_id;
  }

  static convertRealDate(date: string, time?: string) {
    if (date) {
      if (!time) {
        return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10));
      } else {
        return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10)
          , +time.substring(0, 2), +time.substring(3, 5));
      }
    }
    return null;
  }

  static initFileInput(className: string) {
    setTimeout(function () {
      jQuery('.' + className).uniform({
        fileButtonClass: 'action btn bg-green-400'
      });
    }, 100);
  }

  static initDatePicker(className: string, timePicker: boolean) {
    jQuery('.' + className).daterangepicker({
      timePicker: timePicker,
      timePicker24Hour: true,
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });

  }

  static convertDateServer(date: string) {
    const HH = date.substring(11, 13);
    const mm = date.substring(14, 16);
    return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2) + (HH && mm ? " " + HH + ":" + mm : "");
  }

  static initCheckBox(classe: string) {
    const checkBox = jQuery('.' + classe);

    checkBox.uniform({
      radioClass: 'choice'
    });
  }

  static setRadioBoxValue(className: any, value: any) {
    setTimeout(function () {
      jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
      jQuery.uniform.update('.' + className + '[value=' + value + ']');
    }, 5);
  }

}

