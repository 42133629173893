import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginComponent} from "./login/login.component";
import {AppRoutingModule} from "./app.routing";
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {AuthService} from "./shared/services/auth.service";
import {CanActivateViaAuthGuard} from "./shared/services/guards/auth-guard.service";
import {StorageService} from "./shared/services/storage.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SharedService} from "./shared/services/shared.service";
import {SessionStageModule} from "./session-stage/session-stage.module";
import {SessionStageService} from "./shared/services/session-stage.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ManageEvaluationModule} from "./manage-evaluation/manage-evaluation.module";
import {EvaluationService} from "./shared/services/evaluation.service";
import {SharedModule} from "./shared/shared.module";
import {D3Module} from "./d3/d3.module";
import {StatistiquesServicesModule} from "./statistiques-evaluations/statistiques-services.module";
import {TokenInterceptor} from "./shared/token.interceptor";
import {SummerAffectService} from "./shared/services/summer-affect.service";
import {StudentsService} from "./shared/services/students.service";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FullLayoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SessionStageModule,
    ManageEvaluationModule,
    StatistiquesServicesModule,
    D3Module
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthService,
    CanActivateViaAuthGuard,
    StorageService,
    SharedService,
    SessionStageService,
    EvaluationService,
    SummerAffectService,
    StudentsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
