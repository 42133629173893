import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {Credentials} from "../models/credentials";
import {StorageService} from "./storage.service";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/internal/operators";
import {Admin} from "../models/Admin";
import {environment} from "../../../environments/environment";


@Injectable()
export class AuthService extends GenericService {

  loggedAdmin: Admin;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
    this.loggedAdmin = <Admin> storageService.read(StorageService.adminKey);
  }


  login(credentials: Credentials) {
    const url = environment.baseUrl + '/signin';
    console.log("login", JSON.stringify(credentials));
    return this.http.post(url, {
      "email": credentials.login,
      "password": credentials.password
    }, {
      headers: this.headers
    });
  }

  isLoggedIn() {
    return this.storageService.read(StorageService.tokenAdminKey) != null;
  }


  getToken() {
    return <string>this.storageService.read(StorageService.tokenAdminKey)
  }

  clear() {
    this.storageService.removeAll();
  }

  getLoggedAdmin() {
    const url = environment.baseUrl + "/admin/me";

    return this.http.get<any>(url).pipe(catchError(this.handleErrors));
  }

}
