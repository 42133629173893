import {Component, OnInit} from '@angular/core';
import {Utils} from "../../shared/utils";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {Periode} from "../../shared/models/Periode";
import {Groupe} from "../../shared/models/Groupe";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {S_Periode} from "../../shared/models/S_Periode";
import {Etudiant} from "../../shared/models/Etudiant";
import {SharedService} from "../../shared/services/shared.service";
import {Session_Stage} from "../../shared/models/Session_Stage";

declare var jQuery: any;

@Component({
  selector: 'app-list-affectation',
  templateUrl: './list-affectation.component.html',
  styleUrls: ['./list-affectation.component.css']
})
export class ListAffectationComponent implements OnInit {

  periodes: Periode[] = [];
  groupes: Groupe[] = [];
  busy: Subscription;
  sessionStageId: number;
  students: Etudiant[] = [];

  speriodes: S_Periode[] = [];
  sessionStage: Session_Stage = new Session_Stage();

  selectedPeriode: Periode = new Periode();

  constructor(private sessionStageService: SessionStageService,
              private sharedService: SharedService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.initializeSelectFiltrage();
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'));

    this.getSessionStage();
    this.getPeriodesGroups();

  }


  getSessionStage() {
    this.sessionStageService.getSessionStageById(this.sessionStageId)
      .subscribe(
        (data: Session_Stage) => {
          this.sessionStage = data;
        }
      )
  }

  initializeSelectFiltrage() {
    const selectFiltrage = jQuery('.select-filtrage');

    selectFiltrage.select2();

  }

  getPeriodesGroups() {


    this.busy = this.sessionStageService.getPeriodesGroups(this.sessionStageId)
      .subscribe(
        (data: any) => {
          // data : periodes , groupes
          this.periodes = data.periodes;
          this.groupes = data.groupes;
          setTimeout(function () {
            Utils.initializeNavigation('navigation-list-affectation');
          }, 20);
        }
      )
  }

  getStudentsByPeriodeSGroupe(indexPeriode, indexGroupe, indexSgroupe) {

    this.selectedPeriode = this.periodes[indexPeriode];

    this.busy = this.sessionStageService.getStudentsByPeriodeSGroupe(this.sessionStageId,
      this.periodes[indexPeriode].periode_id,
      this.groupes[indexGroupe].sgroupes[indexSgroupe].s_groupe_id)
      .subscribe(
        (data: any) => {
          // data speriodes , students
          this.students = data.students;
          this.speriodes = data.speriodes;
          Utils.initializeDataTables('student-table', 20, 3)
        }
      )
  }

}
