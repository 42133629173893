import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";
import {GenericService} from "./generic.service";
import {CriterePortfolio} from "../models/CriterePortfolio";

@Injectable({
  providedIn: 'root'
})
export class PortfolioService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getAllPortfolioCriteres() {
    const url = environment.baseUrl + "/portfolio/criteres";
    return this.http.get<any>(url);
  }

  editEvaluationPortfolio(evaluationId: number, studentId: number, globalEvaluation: number, criteres: CriterePortfolio[]) {
    const url = environment.baseUrl + "/portfolio/evaluation/" + evaluationId + "/student/" + studentId + "/edit";
    return this.http.put<any>(url, {
      global_validation: globalEvaluation,
      criteres: criteres
    });
  }

}
