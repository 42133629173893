import {NgModule} from '@angular/core';

import {ManageStudentsRoutingModule} from './manage-students-routing.module';
import {ListStudentsComponent} from './list-students/list-students.component';
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    SharedModule,
    ManageStudentsRoutingModule
  ],
  declarations: [ListStudentsComponent]
})
export class ManageStudentsModule {
}
