import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Niveau} from "../../shared/models/Niveau";
import {Periode} from "../../shared/models/Periode";
import * as XLSX from 'xlsx';
import {EcosService} from "../../shared/services/ecos.service";
import {Utils} from "../../shared/utils";
import {PeriodeService} from "../../shared/services/periode.service";
import {SharedService} from "../../shared/services/shared.service";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-import-note',
  templateUrl: './import-note.component.html',
  styleUrls: ['./import-note.component.css']
})
export class ImportNoteComponent implements OnInit {


  submitted: boolean;
  busy: Subscription;

  periodes: Periode[] = [];
  fixPeriodes: Periode[] = [];

  niveaux: Niveau[] = [];

  excelData: any;

  data: ObjectSended = new ObjectSended();

  constructor(private ecosServices: EcosService,
              private periodeService: PeriodeService,
              private sharedService: SharedService) {
  }

  ngOnInit() {

    Utils.initSelect('select-niveau');
    Utils.initSelect('select-periode');
    Utils.initFileInput('file-import-data');

    this.onChangeNiveau();

    this.loadPeriodes();
    this.loadLevels();
  }


  public onFileChange(evt: any) {
    console.log("Change !!!");
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      console.log("On load !!");
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = XLSX.utils.sheet_to_json(ws, {raw: true});

      console.log(this.excelData);
      for (let i = 0; i < this.excelData.length; i++) {
        this.data.students.push(
          {
            cin: this.excelData[i].cin,
            note: this.excelData[i].note
          }
        )
      }
      console.log(this.data);
    }
    reader.readAsBinaryString(target.files[0]);
  }


  importDataEcos() {
    this.submitted = true;

    this.data.periode_id = parseInt(jQuery('.select-periode').val(), 0);

    console.log(this.data);
    if (this.isEmptyData()) {
      swal('Warning', 'remplir tous les champs', 'warning');
      return;
    }

    console.log(this.data);
    this.busy = this.ecosServices.importNote(this.data)
      .subscribe(
        (data) => {
          swal('Succées', 'Note imported success', 'success');
        },
        (error) => {
          swal('Erreur', 'Une erreur est survenu', 'error');
        }
      )
  }

  isEmptyData() {
    return !this.data.niveau_id || !this.data.periode_id || this.data.students.length === 0;
  }

  private onChangeNiveau() {
    const selectNiveau = jQuery('.select-niveau');
    const baseContext = this;


    selectNiveau.on('change', function () {
      baseContext.data.niveau_id = parseInt(jQuery(this).val(), 0);
      baseContext.periodes = [];
      baseContext.periodes = baseContext.fixPeriodes.filter(
        periode => {
          if (periode.session_stage.niveau_id === baseContext.data.niveau_id) {
            return periode;
          }
        }
      );
      console.log(baseContext.periodes);
    });
  }

  private loadPeriodes() {
    this.busy = this.periodeService.getAllPeriodeBySessionStageId(0)
      .subscribe(
        (data: Periode[]) => {
          this.fixPeriodes = data;
        },
        (error) => {

        }
      )
  }

  private loadLevels() {
    this.busy = this.sharedService.getNiveaux()
      .subscribe(
        (data: Niveau[]) => {
          this.niveaux = data;
        },
        (error) => {

        }
      )
  }
}

export class ObjectSended {
  niveau_id: number;
  periode_id: number;
  students: StudentNote[] = [];
}

class StudentNote {
  cin: string;
  note: number;
}
