import {Injectable} from '@angular/core';
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CoordinatorService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getAllTeachers() {
    const url = environment.baseUrl + '/teacher';


    return this.http.get(url);
  }


  getAllCoordinateurs() {
    const url = environment.baseUrl + '/teacher/coordinateurs';
    return this.http.get(url);
  }
}
