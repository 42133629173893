import {Etudiant} from "./Etudiant";
import {Enseignant} from "./Enseignant";
import {S_Periode} from "./S_Periode";
import {Service} from "./Service";
import {PeriodeStage} from "./PeriodeStage";
import {Evaluation_Critere} from "./Evaluation_Critere";
import {Evaluation_Critere_Etudiant} from "./Evaluation_Critere_Etudiant";
import {EvaluationCClinique} from "./EvaluationCClinique";
import {EvaluationPortfolio} from "./EvaluationPortfolio";

export class Evaluation {
  public evaluation_id: number;
  public note: number;
  public remarque: string;
  public s_periode_id: number;
  public etudiant_id: number;
  public enseignant_id: number;
  public service_id: number;

  public etudiant: Etudiant = new Etudiant();
  public enseignant: Enseignant = new Enseignant();
  public speriode: S_Periode = new S_Periode();
  public service: Service = new Service();

  public periode_stage: PeriodeStage = new PeriodeStage();
  public evaluations_criteres: Evaluation_Critere[];
  public evaluations_criteres_etudiants: Evaluation_Critere_Etudiant[];

  public commentaire_etudiant: string;
  public moyenne_evals: number;

  coordinateur_id: number;

  public ecos_evaluation: EvaluationCClinique;
  public evaluation_portfolio: EvaluationPortfolio;
}

