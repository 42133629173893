import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeliberationResultatsInternesComponent} from "./deliberation-resultats-internes/deliberation-resultats-internes.component";
// Layouts
export const routes: Routes = [
  {
    path: 'deliberation',
    component: DeliberationResultatsInternesComponent
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageResultatsInternesRouting {

}
