import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExterneEvaluationComponent} from './externe-evaluation/externe-evaluation.component';
import {ManageEvaluationRouting} from "./manage-evaluation.routing";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    ManageEvaluationRouting,
    SharedModule
  ],
  declarations: [ExterneEvaluationComponent]
})
export class ManageEvaluationModule {
}
