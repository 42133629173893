import {ResultGroupe} from "./ResultGroupe";
import {ResultInterne} from "./ResultInterne";
import {Niveau} from "./Niveau";

export class Etudiant {
  public etudiant_id: number;
  public nom: string;
  public prenom: string;
  public CIN: string;
  public carte_Etudiant: string;
  public email: string;
  public qr_code: string;
  public niveau_id: number;

  public classement: number;

  public group_results: ResultGroupe[] = [];
  public result_interne: ResultInterne;

  public niveau: Niveau = new Niveau();
  public visible: number;
}
