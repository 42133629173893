import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Evaluation} from "../../shared/models/Evaluation";
import {GCompetanceClinique} from "../../shared/models/GCompetanceClinique";
import {ActivatedRoute, Router} from "@angular/router";
import {EcosService} from "../../shared/services/ecos.service";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {Utils} from "../../shared/utils";

declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-edit-ecos',
  templateUrl: './edit-ecos.component.html',
  styleUrls: ['./edit-ecos.component.css']
})
export class EditEcosComponent implements OnInit {

  busy: Subscription;

  evaluationId: number;
  sessionStageId: number;
  evaluation: Evaluation = new Evaluation();
  gcompetances: GCompetanceClinique[] = [];

  constructor(private route: ActivatedRoute,
              private ecosService: EcosService,
              private router: Router,
              private evaluationService: EvaluationService) {
  }

  ngOnInit() {
    this.evaluationId = parseInt(this.route.snapshot.paramMap.get('evaluationId'), 10);
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'), 10);
    if (!this.evaluationId || !this.sessionStageId) {
      this.router.navigateByUrl('/session-stage/list');
    }

    this.getEvaluation();
  }

  getEvaluation() {
    this.busy = this.evaluationService.getEvaluationById(this.evaluationId)
      .subscribe(
        (data) => {
          this.evaluation = data;
          if (!this.evaluation.ecos_evaluation) {
            this.router.navigateByUrl('/session-stage/list');
            return;
          }
          this.getAllGCompetances();
        },
        (error) => {

        }
      )
  }

  getAllGCompetances() {
    this.busy = this.ecosService.getAllGCompetances()
      .subscribe(
        (data) => {
          this.gcompetances = data;
          this.affectValuesToCompetances();
          this.initCheckBox();
          this.initRadioBox();
        },
        (error) => {
        }
      )
  }

  affectValuesToCompetances() {
    const validations = this.evaluation.ecos_evaluation.validations;
    this.gcompetances.forEach(
      gcompetance => {
        gcompetance.competances.forEach(
          competance => {
            let index = validations.map(item => {
              return item.competance_clinique_id;
            }).indexOf(competance.competance_clinique_id);
            competance.value = validations[index].value !== -1 ? validations[index].value : 1;
            competance.na = validations[index].value === -1;
          }
        )
      }
    );
  }

  initCheckBox() {
    const baseContext = this;
    for (let i = 0; i < this.gcompetances.length; i++) {
      let gcompetance = this.gcompetances[i];
      for (let j = 0; j < gcompetance.competances.length; j++) {
        setTimeout(function () {
          baseContext.initItemCheckbox(i, j);
        })
      }
    }
  }

  initRadioBox() {
    const baseContext = this;
    for (let i = 0; i < this.gcompetances.length; i++) {
      let gcompetance = this.gcompetances[i];
      for (let j = 0; j < gcompetance.competances.length; j++) {
        setTimeout(function () {
          baseContext.initItemRadioBox(i, j);
        })
      }
    }
  }

  initItemRadioBox(indexG: number, index: number) {
    const baseContext = this;
    const radioBox = jQuery('.radioBox-critere-' + indexG + '-' + index);

    radioBox.uniform({
      radioClass: 'choice'
    });
    Utils.setValueToRadioBox('radioBox-critere-' + indexG + '-' + index, baseContext.gcompetances[indexG].competances[index].value, 20);
    radioBox.on("change", function () {
      baseContext.gcompetances[indexG].competances[index].value = parseInt(jQuery(this).val(), 0);
    });
  }

  initItemCheckbox(indexG, index: number) {
    const baseContext = this;
    const checkBox = jQuery('.checkbox-inline-na-' + indexG + '-' + index);

    checkBox.uniform({
      radioClass: 'choice'
    });
    Utils.setValueToCheckBox('checkbox-inline-na-' + indexG + '-' + index, baseContext.gcompetances[indexG].competances[index].na);
    checkBox.on("change", function () {
      baseContext.gcompetances[indexG].competances[index].na = !baseContext.gcompetances[indexG].competances[index].na;
    });
  }

  submitEvaluation() {
    const baseContext = this;
    swal({
      title: 'Vous etes sur?',
      text: "Confirmation d'evaluation de ce stage!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }).then(isConfirm => {
      if (isConfirm) {
        baseContext.busy = baseContext.ecosService
          .editEvaluationEcos(baseContext.evaluationId, baseContext.evaluation.etudiant_id, baseContext.gcompetances)
          .subscribe(
            (data) => {
              swal("Succées", "Evaluation modifié avec succées", "success");
              baseContext.router.navigateByUrl('/session-stage/' + baseContext.sessionStageId + '/tableau-board');
            }
          )

      }
    })
  }

}
