import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {FilterResult} from "../models/ResultGroupe";
import {environment} from "../../../environments/environment";

@Injectable()
export class StudentsService extends GenericService {
  context: any;

  filterResult: FilterResult = new FilterResult();


  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  getStudentsByLevel(levelId: number) {
    const url = environment.baseUrl + '/students/level/' + levelId;

    return this.http.get(url);
  }

  ignoreStudents(studentId: any, status: number) {
    const url = environment.baseUrl + '/students/' + studentId + '/ignore';

    return this.http.put(url, {
      status: status
    });
  }
}
