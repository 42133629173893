import {Service} from "./Service";
import {SGroupe_Service} from "./SGroupe_Service";

export class S_Groupe {
  public s_groupe_id: number;
  public label: string;
  public capacity: number;
  public services: Service[] = [];
  public nb_service: string;
  public sgroupeservice: SGroupe_Service[] = [];
}
