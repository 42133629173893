import {Etudiant} from "./Etudiant";
import {Evaluation} from "./Evaluation";
import {Evaluation_Critere} from "./Evaluation_Critere";

export class ResultGroupe {

  public result_groupe_id: number;
  public note_stage: number;
  public note_ecos: number;
  public groupe_id: number;
  public periode_id: number;
  public etudiant_id: number;
  public etudiant: Etudiant;
  public evaluations: Evaluation[];
  public evaluations_criteres: Evaluation_Critere[];
  public decision: number;
  public commentaire: string;

  /* Lié au UI */
  noteEcosEditable: boolean;


}

export class FilterResult {
  groupId: number;
  periodeId: number;
}
