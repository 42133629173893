import {Component, Input, OnInit} from '@angular/core';
import {Service} from "../../shared/models/Service";
import {CoordinatorService} from "../../shared/services/coordinator.service";
import {Utils} from "../../shared/utils";
import {Coordinator, CoordinatorRequest} from "../../shared/models/Coordinator";
import {SharedService} from "../../shared/services/shared.service";
import {Subscription} from "rxjs";
import {Niveau} from "../../shared/models/Niveau";
import {Enseignant} from "../../shared/models/Enseignant";
import {ServiceService} from "../../shared/services/service.service";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-add-coordinator',
  templateUrl: './add-coordinator.component.html',
  styleUrls: ['./add-coordinator.component.css']
})
export class AddCoordinatorComponent implements OnInit {

  @Input()
  services: Service[] = [];


  coordinator: CoordinatorRequest = new CoordinatorRequest();
  busy: Subscription;
  niveaux: Niveau[] = [];

  submitted: boolean;

  enseignants: Enseignant[] = [];

  constructor(private coordinatorService: CoordinatorService,
              private sharedService: SharedService,
              private serviceService: ServiceService) {


  }

  ngOnInit() {

    this.getAllLevels();
    this.getAllTeachers();
    Utils.initSelect('select-niveau');
    Utils.initSelect('select-service');
    Utils.initSelect('select-coordinateurs');

    this.onChangeSelectNiveau();
    this.onChangeSelectService();
  }

  onChangeSelectNiveau() {
    const selectNiveau = jQuery('.select-niveau');
    const selectService = jQuery('.select-service');
    const baseContext = this;
    selectNiveau.on('change', function () {

      const levelId = parseInt(jQuery(this).val(), 0);
      const serviceId = parseInt(selectService.val(), 0);

      baseContext.coordinator.niveau_id = levelId;
      if (levelId && serviceId) {
        baseContext.getCoordinatorsIdByServiceAndLevel(levelId, serviceId);
      }
    });
  }

  onChangeSelectService() {
    const selectNiveau = jQuery('.select-niveau');
    const selectService = jQuery('.select-service');
    const baseContext = this;
    selectService.on('change', function () {

      const serviceId = parseInt(jQuery(this).val(), 0);
      const levelId = parseInt(selectNiveau.val(), 0);

      baseContext.coordinator.service_id = serviceId;
      if (levelId && serviceId) {
        baseContext.getCoordinatorsIdByServiceAndLevel(levelId, serviceId);
      }
    });
  }

  getCoordinatorsIdByServiceAndLevel(levelId, serviceId) {
    const indexService = this.services.map(
      service => {
        return service.service_id
      }
    ).indexOf(serviceId);

    console.log(this.services[indexService]);
    const teachers = this.services[indexService]
      .coordinateurs.filter(
        coordinateur => {
          if (coordinateur.niveau_id === levelId) {
            return coordinateur;
          }
        }
      );
    console.log(teachers);

    const teacherIds = teachers.map(
      teacher => {
        return teacher.enseignant.enseignant_id;
      }
    );
    console.log(teacherIds);
    this.coordinator.enseignantIds = teacherIds;
    Utils.setValuesjQueryCmp('select-coordinateurs', teacherIds, 20);


  }


  getAllTeachers() {
    this.coordinatorService.getAllTeachers()
      .subscribe(
        (data: Enseignant[]) => {
          this.enseignants = data;
        }
      )
  }

  getAllLevels() {
    this.busy = this.sharedService.getNiveaux()
      .subscribe(
        (data: Niveau[]) => {
          this.niveaux = data;
        }
      )
  }

  isEmptyChamps() {
    return !this.coordinator.service_id || !this.coordinator.niveau_id || this.coordinator.enseignantIds.length === 0;
  }

  affectCoordinatorsToService() {

    this.coordinator.enseignantIds = jQuery('.select-coordinateurs').val();
    this.submitted = true;
    console.log(this.coordinator);
    if (this.isEmptyChamps()) {
      swal('Warning', 'Vous devez remplir les champs', 'warning');
      return;
    }

    const indexService = this.services.map(
      service => {
        return service.service_id
      }
    ).indexOf(this.coordinator.service_id);
    this.busy = this.serviceService.affectCoordiateursToService(this.coordinator)
      .subscribe(
        (data: Service) => {
          this.coordinator = new CoordinatorRequest();
          this.submitted = false;
          jQuery('.select-niveau').val(0).trigger('change');
          jQuery('.select-service').val(0).trigger('change');
          jQuery('.select-coordinateurs').val([]).trigger('change');
          swal("Succées", "Affectation avec succées", "success");
          this.services[indexService] = data;
          this.serviceService.changeServices(this.services);
        },
        (error) => {

        }
      )
  }


}
