import {Component, OnInit} from '@angular/core';
import {Session_Stage} from "../../shared/models/Session_Stage";
import {Contrainte, Periode} from "../../shared/models/Periode";
import {Groupe} from "../../shared/models/Groupe";
import {S_Groupe} from "../../shared/models/S_Groupe";
import {Service} from "../../shared/models/Service";
import {Subscription} from "rxjs";
import {Niveau} from "../../shared/models/Niveau";
import {SharedService} from "../../shared/services/shared.service";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../shared/utils";
import {SGroupe_Service} from "../../shared/models/SGroupe_Service";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'add-session-stage',
  templateUrl: './add-session-stage.component.html',
  styleUrls: ['./add-session-stage.component.css']
})
export class AddSessionStageComponent implements OnInit {


  session: Session_Stage = new Session_Stage();

  niveaux: Niveau [] = [];

  sessionStageId: number;
  editAction: boolean;


  busy: Subscription;
  submitted: boolean;
  services: Service[] = [];
  durations: Duration [] = [
    {
      value: 1,
      label: "1S"
    },
    {
      value: 2,
      label: "2S"
    },
    {
      value: 3,
      label: "3S"
    },
    {
      value: 4,
      label: "4S"
    },
    {
      value: 5,
      label: "5S"
    },
    {
      value: 6,
      label: "6S"
    }
  ];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private sessionStageService: SessionStageService) {
    this.editAction = this.router.url.indexOf('edit') !== -1;

  }


  ngOnInit() {

    if (!this.editAction) {
      this.initializeDate('.date-choix-open');
      this.initializeDate('.date-choix-close');
      this.initializeDate('.date-service-open');
      this.initializeDate('.date-service-close');
      this.getAllNiveaux();
    }

    this.getAllServices();


    if (this.editAction) {
      this.route.params.subscribe(
        params => {
          this.sessionStageId = +params["sessionStageId"];
          this.loadSessionStage(this.sessionStageId);
        }
      )

    }

  }

  loadSessionStage(sessionStageId: number) {
    const baseContext = this;
    this.sessionStageService.getSessionStageById(sessionStageId)
      .subscribe(
        (data: Session_Stage) => {
          this.session = data;
          baseContext.getAllNiveaux();
          this.session.nb_p_g = this.session.groupes.length + "";
          baseContext.initializeDate('.date-choix-open');
          baseContext.initializeDate('.date-choix-close');
          baseContext.initializeDate('.date-service-open');
          baseContext.initializeDate('.date-service-close');

          for (let i = 0; i < this.session.groupes.length; i++) {
            setTimeout(function () {
              baseContext.initializeDate('.date-debut-' + i, i);
              baseContext.initializeDate('.date-fin-' + i, i);
            }, 20);
            this.session.groupes[i].nb_sg = this.session.groupes[i].sgroupes.length + "";
            for (let j = 0; j < this.session.groupes[i].sgroupes.length; j++) {
              setTimeout(function () {
                baseContext.initializeServices('.select-service', i, j);
              }, 20);

            }
          }
          /* const session = data;
           this.session.name = session.name;
           this.session.niveau_id = session.niveau_id;
           this.session.capacity = session.capacity;
           this.session.nb_p_g = session.groupes.length;*/
        },
        (error) => {

        }
      )
  }

  continueForm(event) {

    if (isNaN(parseInt(this.session.nb_p_g))) {
      return;
    }
    const baseContext = this;
    this.session.periodes = [];
    this.session.groupes = [];
    for (let i = 0; i < parseInt(this.session.nb_p_g); i++) {
      this.session.periodes.push(new Periode());
      setTimeout(function () {
        baseContext.initializeDate('.date-debut-' + i, i);
        baseContext.initializeDate('.date-fin-' + i, i);
      }, 20);
    }
    for (let i = 0; i < parseInt(this.session.nb_p_g); i++) {
      this.session.groupes.push(new Groupe());
    }

  }

  sgroupecontinue(event, groupeIndex) {

    if (isNaN(parseInt(this.session.groupes[groupeIndex].nb_sg))) {
      return;
    }

    const baseContext = this;
    this.session.groupes[groupeIndex].sgroupes = [];

    for (let i = 0; i < parseInt(this.session.groupes[groupeIndex].nb_sg); i++) {
      this.session.groupes[groupeIndex].sgroupes.push(new S_Groupe());
      setTimeout(function () {
        // baseContext.initializeDuration('.select-duration', groupeIndex, i);
        baseContext.initializeServices('.select-service', groupeIndex, i);
      }, 20);
    }
  }

  sgroupeservicecontinue(event, groupeIndex, sgroupeIndex) {
    console.log(groupeIndex, sgroupeIndex);
    if (isNaN(parseInt(this.session.groupes[groupeIndex].sgroupes[sgroupeIndex].nb_service, 0))) {
      return;
    }
    if (parseInt(this.session.groupes[groupeIndex].sgroupes[sgroupeIndex].nb_service, 0) > 10) {
      return;
    }

    this.session.groupes[groupeIndex].sgroupes[sgroupeIndex].sgroupeservice = [];

    for (let i = 0; i < parseInt(this.session.groupes[groupeIndex].sgroupes[sgroupeIndex].nb_service, 0); i++) {
      this.session.groupes[groupeIndex].sgroupes[sgroupeIndex].sgroupeservice.push(new SGroupe_Service());
    }
  }

  initializeDuration(className: string, i: number, j: number) {
    const baseContext = this;
    const selectDuration = jQuery(className + '-' + i + '-' + j);

    selectDuration.select2();

    selectDuration.on('change', function () {
      // baseContext.session.groupes[i].sgroupes[j].duration = parseInt(selectDuration.val());
    })
  }

  initializeServices(className: string, i: number, j: number) {
    const baseContext = this;
    const selectService = jQuery(className + '-' + i + '-' + j);

    selectService.select2();
    selectService.on("select2:select", function (e) {
      console.log(e.params.data.id);
      baseContext.session.groupes[i].sgroupes[j]
        .services.push({service_id: parseInt(e.params.data.id)});
    });
    selectService.on("select2:unselect", function (e) {
      console.log("select2:unselect", e.params.data.id);
      const index = baseContext.session.groupes[i].sgroupes[j].services.map(
        function (e) {
          return e.service_id
        }).indexOf(parseInt(e.params.data.id), 0);
      if (index > -1) {
        baseContext.session.groupes[i].sgroupes[j].services.splice(index, 1);
      }
    });
    if (this.editAction) {
      let services = [];
      for (let k = 0; k < this.session.groupes[i].sgroupes[j].services.length; k++) {
        console.log(services);
        services.push(this.session.groupes[i].sgroupes[j].services[k].service_id);
      }
      selectService.val(services).trigger('change');
    }
  }

  addSessionStage() {
    console.log(this.session);

    // return;
    if (!this.editAction) {
      this.busy = this.sessionStageService.addSessionStage(this.session)
        .subscribe(
          (data) => {
            swal('Succées', 'Session ajoutée avec succées', 'success');
            this.router.navigate(['/session-stage/list']);
            // console.log(data);
          }
        )
    } else {
      this.busy = this.sessionStageService.editSessionStage(this.sessionStageId, this.session)
        .subscribe(
          (data) => {
            swal('Succées', 'Session modifiée avec succées', 'success');
            this.router.navigate(['/session-stage/list']);
          },
          (error) => {
            if (error.status === 402) {
              swal('Warning', "Vous n'avez pas la possibilité de modifier cette session , les étudiants ont commencé le choix", 'warning');
            }
          }
        )
    }
  }

  private initializeSelectNiveau() {
    const selectNiveau = jQuery('.select-niveau');
    const baseContext = this;

    selectNiveau.select2();
    selectNiveau.on('change', function () {
      baseContext.session.niveau_id = parseInt(selectNiveau.val());
    });
    if (baseContext.editAction) {
      setTimeout(function () {
        selectNiveau.val(baseContext.session.niveau_id).trigger("change");
      }, 20);

    }
  }

  private getAllNiveaux() {
    const baseContext = this;
    this.sharedService.getNiveaux()
      .subscribe((data: Niveau[]) => {
        this.niveaux = data;
        setTimeout(function () {
          baseContext.initializeSelectNiveau();
        }, 20);
      })
  }

  private getAllServices() {
    this.sharedService.getServices()
      .subscribe((data: Service[]) => {
        this.services = data;
      })
  }

  addContrainte(periodeIndex) {
    this.session.periodes[periodeIndex].contraintes.push(new Contrainte());
  }

  private initializeDate(className, index?) {

    const baseContext = this;
    const date = jQuery(className);

    date.daterangepicker({
      "singleDatePicker": true,
      "minDate": new Date(),
      "locale": {
        "format": "DD/MM/YYYY"
      }
    });
    date.on('change', function () {
      if (className.indexOf('debut') !== -1) {
        baseContext.session.periodes[index].start_date = date.val();
      }
      if (className.indexOf('fin') !== -1) {
        baseContext.session.periodes[index].end_date = date.val();
      }
      if (className.indexOf('choix-open') !== -1) {
        baseContext.session.date_choice_open = date.val();
      }
      if (className.indexOf('choix-close') !== -1) {
        baseContext.session.date_choice_close = date.val();
      }
      if (className.indexOf('service-open') !== -1) {
        baseContext.session.date_service_open = date.val();
      }
      if (className.indexOf('service-close') !== -1) {
        baseContext.session.date_service_close = date.val();
      }
    });

    if (this.editAction) {
      if (className.indexOf('debut') !== -1) {
        date.val(Utils.convertDate(baseContext.session.periodes[index].start_date)).trigger("change");
      }
      if (className.indexOf('fin') !== -1) {
        date.val(Utils.convertDate(baseContext.session.periodes[index].end_date)).trigger("change");
      }
      if (className.indexOf('choix-open') !== -1) {
        date.val(Utils.convertDate(baseContext.session.date_choice_open)).trigger("change");
      }
      if (className.indexOf('choix-close') !== -1) {
        date.val(Utils.convertDate(baseContext.session.date_choice_close)).trigger("change");
      }
      if (className.indexOf('service-open') !== -1) {
        date.val(Utils.convertDate(baseContext.session.date_service_open)).trigger("change");
      }
      if (className.indexOf('service-close') !== -1) {
        date.val(Utils.convertDate(baseContext.session.date_service_close)).trigger("change");
      }
    }
  }
}

export class Duration {
  public value: number;
  public label: string;
}
