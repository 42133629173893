import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddJuryComponent} from "./add-jury/add-jury.component";
import {ListResultsComponent} from "./list-results/list-results.component";
import {DetailsEvaluationJuryComponent} from "./details-evaluation-jury/details-evaluation-jury.component";

// Layouts
export const routes: Routes = [
  {
    path: 'add',
    component: AddJuryComponent
  },
  {
    path: 'list-results/:session_stage_id',
    component: ListResultsComponent
  },
  {
    path: 'list-results/:session_stage_id/details-etudiant-result',
    component: DetailsEvaluationJuryComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageJuryRouting {
}
