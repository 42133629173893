import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GlobalStatsComponent} from "./global-stats/global-stats.component";
import {EvaluationsEtudiantsComponent} from "./evaluations-etudiants/evaluations-etudiants.component";

// Layouts
export const routes: Routes = [

  {
    path: 'global',
    component: GlobalStatsComponent
  },
  {
    path:'evaluations-etudiants',
    component: EvaluationsEtudiantsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatistiquesServicesRouting {
}
