export class AffectationRequest {
  public etudiant_id: number;
  public affectations: AffectationItem [] = [];
}

export class AffectationItem {
  public service_id: number;
  public periode_id: number;
  public periode_stage_id: number;
  public speriodeLabel: string;
}
