import {Component, OnInit} from '@angular/core';
import {Niveau} from "../../shared/models/Niveau";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../shared/services/shared.service";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {Subscription} from "rxjs/Rx";
import {Groupe} from "../../shared/models/Groupe";
import {Enseignant} from "../../shared/models/Enseignant";
import {Utils} from "../../shared/utils";
import {JuryGroupe} from "../../shared/models/Jury_Groupe";

declare let swal: any;

@Component({
  selector: 'app-add-jury',
  templateUrl: './add-jury.component.html',
  styleUrls: ['./add-jury.component.css']
})
export class AddJuryComponent implements OnInit {
  sessions: Niveau[] = [];
  busy: Subscription;
  sessionSelected = false;
  groupes: Groupe[];
  groupeSelected = false;
  selectedGroupeId: number;
  enseignants: Enseignant[];
  selectedEnseignantId: any;
  enseignantSelected = false;
  jurysGroupes: JuryGroupe[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private sessionStageService: SessionStageService) {
  }

  ngOnInit() {
    this.getAllSessions();
    this.getAllEnseignants();
    this.getGroupeWhereJuryAffected();
  }

  private getAllSessions() {
    const baseContext = this;
    this.busy = this.sessionStageService.getAllSessionStage()
      .subscribe((data: Niveau[]) => {
        this.sessions = data;
      })
  }

  onSelectSessionStage($event: any) {
    this.getGroupesBySessionStageId($event);
  }

  getGroupesBySessionStageId($event: any) {
    const baseContext = this;
    this.busy = this.sharedService.getGroupesBySessionId($event)
      .subscribe((data: Groupe[]) => {
        this.groupes = data;
        this.groupes.forEach(groupe => {
          if (groupe.jurys && groupe.jurys.length > 0) {
            groupe.nomsJurys = "";
            groupe.jurys.forEach(jury => {
              groupe.nomsJurys += " - " + jury.nom + " " + jury.prenom;
            })
          } else {
            groupe.nomsJurys = "";
          }
        });
        this.sessionSelected = true;
      })

  }

  onSelectGroupe($event: any) {
    this.groupeSelected = true;
    this.selectedGroupeId = $event;
  }

  getAllEnseignants() {
    // TODO Get Only unassigned
    this.sharedService.getAllEnseignant().subscribe((data: Enseignant[]) => {
      this.enseignants = data;
    })
  }

  onSelectEnseignant($event: any) {
    this.enseignantSelected = true;
    this.selectedEnseignantId = $event
  }

  affecterJury() {
    this.busy = this.sharedService.affecterJury(this.selectedEnseignantId, this.selectedGroupeId).subscribe((data: JuryGroupe) => {
        swal('Succès', 'Affectation terminée en Succès', 'success');
        // this.sessionSelected = false;
        // this.groupeSelected = false;
        this.enseignantSelected = false;
        // this.affectedGroupes.push(data);
        const groupe = this.jurysGroupes.map(smth => smth.groupe).find((el) => {
          return (el.groupe_id === data.groupe_id)
        });
        if (groupe) {
          groupe.jury = data.jury;
        } else {
          this.jurysGroupes.push(data);
        }

        Utils.initializeDataTables("datatable", 30, 4);
      },
      (error) => {
        swal('Erreur', 'Une erreur est survenue', 'error');
      });
  }

  getGroupeWhereJuryAffected() {
    this.busy = this.sharedService.getJurysGroupesWhereJuryAffected().subscribe((data: JuryGroupe[]) => {
      this.jurysGroupes = data;
      Utils.initializeDataTables("datatable", 30, 4);
    });
  }

  annulerAffectation(i: number) {
    const baseContext = this;
    swal({
      title: 'Attention !',
      text: 'Êtes-vous sûrs de vouloir supprimer le jury ? ',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Non annuler',
          value: null,
          visible: true
        },
        confirm: {
          text: 'Oui Supprimer',
          vaule: true,
          visible: true
        }
      }

    }).then((isConfirm) => {
      if (isConfirm) {
        console.log(baseContext.jurysGroupes[i].jury_groupe_id);
        baseContext.busy = baseContext.sharedService.resetJury(baseContext.jurysGroupes[i].jury_groupe_id).subscribe((data: Groupe[]) => {
          baseContext.jurysGroupes.splice(i, 1);
          Utils.initializeDataTables("datatable", 30, 4);
          swal("Succées", "Jury supprimer avec succés", "success");
        }, (error) => {
          swal("Erreur", "Erreur leur de la réinitialisation", "error")

        });
      }
    });

  }
}
