import {Component, OnInit} from '@angular/core';
import {DataModel} from "../../d3/interfaces/DataModel";
import {Niveau} from "../../shared/models/Niveau";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SharedService} from "../../shared/services/shared.service";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {Service} from "../../shared/models/Service";
import {StatsService} from "../../shared/services/stats.service";
import {StatService} from "../../shared/models/StatService";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {Critere_Etudiant} from "../../shared/models/Critere_Etudiant";
import {Utils} from "../../shared/utils";
import {Evaluation} from "../../shared/models/Evaluation";

declare let jQuery: any;

@Component({
  selector: 'app-global-stats',
  templateUrl: './global-stats.component.html',
  styleUrls: ['./global-stats.component.css']
})
export class GlobalStatsComponent implements OnInit {
  data: DataModel[] = [];
  niveaux: Niveau [] = [];
  services: Service[] = [];
  stats_services: StatService[];
  selectedNiveauTop5 = "Tous Les Niveaux";
  selectedNiveauIdTop5 = -1;
  selectedCritereId = 0;
  selectedNiveauIdCriteres = -1;
  selectedNiveauIdClassementGeneral = -1;
  selectedNiveauIdDetails = -1;
  selectedServiceIdDetails: number;

  dataTop5: Adata[] = [];
  dataBot5: Adata[] = [];
  dataDetailsService: Adata[] = [];
  criteres: Critere_Etudiant[] = [];
  stats_tableau: StatService[] = [];
  stats_tableau_criteres: StatService[] = [];

  number_evaluations = 0;
  number_etudiants = 0;
  number_evals_services = 0;

  barrier = 2;
  came_from_refresh = false;
  selectedService: Service;
  evaluations: Evaluation[];
  loaded=false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private statsService: StatsService,
              private evaluationServices: EvaluationService,
              private sessionStageService: SessionStageService) {
  }

  ngOnInit() {
    this.data.push(new Adata("psychologie", 4));
    this.data.push(new Adata("pneumologie", 3));
    this.data.push(new Adata("medecine dentaire", 3.4));
    this.data.push(new Adata("cardiovasculaire", 3.33));
    this.data.push(new Adata("generale", 3.7));
    this.getAllNiveaux();
    this.getAllServicesHavingStats();
    this.getAllStatsServices();
    this.getAllNumbers();
    this.getAllCriteresEvaluations();


  }

  private getAllServicesHavingStats() {
    this.statsService.getAllServicesHavingStats()
      .subscribe((data: Service[]) => {
          this.services = data;
          const len = this.services.length;
          if (len > 0)
            this.selectedServiceIdDetails = this.services[0].service_id;
          if (this.number_evaluations != 0 && len > 0)
            this.number_evals_services = Math.floor(this.number_evaluations / this.services.length);
          if (this.came_from_refresh == true) {
            this.barrier -= 1;
            if (this.barrier == 0)
              this.initAllCharts();
          }
        }
      )
  }


  private getAllNiveaux() {
    const baseContext = this;
    this.statsService.getAllNiveauxHavingStats()
      .subscribe((data: Niveau[]) => {
        this.niveaux = data;
        if (this.niveaux.length > 0)
          this.selectedNiveauIdDetails = this.niveaux[0].niveau_id;
        const n = new Niveau();
        n.niveau_id = -1;
        n.label = "Tous Les Niveaux";
        this.niveaux.unshift(n);
        if (this.came_from_refresh == true) {
          this.barrier -= 1;
          if (this.barrier == 0)
            this.initAllCharts();
        }
      })
  }

  onSelectNiveau(chart_name: string, $event: any) {
    if (chart_name == "top5")
      this.initChartTop5($event);
    else if (chart_name == "bot5")
      this.initChartBot5($event);
    else if (chart_name == "classementGeneral") {
      this.selectedNiveauIdClassementGeneral = $event;
      this.initClassementGeneral($event);
    } else if (chart_name == "classementParCriteres")
      this.initClassementParCriteres($event);
    else if (chart_name == "detailsService")
      this.initChartDetailsService($event);
  }

  private getAllStatsServices() {
    const that = this;
    this.statsService.getAllStatsServices().subscribe((data: StatService[]) => {
      this.stats_services = data;
      setTimeout(function () {
        that.initAllCharts();
        that.loaded=true;
      }, 100)
    });
  }

  initAllCharts() {
    this.initChartTop5(-1);
    this.initChartBot5(-1);
    this.initClassementGeneral(-1);
    this.initClassementParCriteres(-1);
    this.initChartDetailsService(-1);
  }

  initChartTop5(niveau_id) {
    // console.log("awesome");
    // console.log(niveau_id);
    let stats_top5 = null;
    if (niveau_id != -1) {
      stats_top5 = this.stats_services
        .filter((s) => {
          return s.niveau_id == niveau_id && s.critere_etudiant_id == null
        })
        .sort((s1, s2) => {
            if (s1.value < s2.value) {
              return 1
            } else if (s1.value > s2.value) {
              return -1
            }
            return 0;
          }
        )
        .slice(0, 5);
      //console.log(stats_top5);
      this.dataTop5 = stats_top5.map((s) => {
        return new Adata(s.service.label, s.value)
      });
    } else {
      stats_top5 = this.stats_services
        .filter((s) => {
          return s.niveau_id == null && s.critere_etudiant_id == null
        })
        .sort((s1, s2) => {
            if (s1.value < s2.value) {
              return 1
            } else if (s1.value > s2.value) {
              return -1
            }
            return 0;
          }
        )
        .slice(0, 5);
      //console.log(stats_top5);
      this.dataTop5 = stats_top5.map((s) => {
        return new Adata(s.service.label, s.value)
      });
    }

  }

  initChartBot5(niveau_id) {
    let stats_top5 = null;
    if (niveau_id != -1) {
      stats_top5 = this.stats_services
        .filter((s) => {
          return s.niveau_id == niveau_id && s.critere_etudiant_id == null
        })
        .sort((s1, s2) => {
            if (s1.value > s2.value) {
              return 1
            } else if (s1.value < s2.value) {
              return -1
            }
            return 0;
          }
        )
        .slice(0, 5);
      console.log(stats_top5);
      this.dataBot5 = stats_top5.map((s) => {
        return new Adata(s.service.label, s.value)
      });
    } else {
      stats_top5 = this.stats_services
        .filter((s) => {
          return s.niveau_id == null && s.critere_etudiant_id == null
        })
        .sort((s1, s2) => {
            if (s1.value > s2.value) {
              return 1
            } else if (s1.value < s2.value) {
              return -1
            }
            return 0;
          }
        )
        .slice(0, 5);

      this.dataBot5 = stats_top5.map((s) => {
        return new Adata(s.service.label, s.value)
      });
    }

  }

  private getAllCriteresEvaluations() {
    this.evaluationServices.getAllCriteresEtudiants().subscribe((data: Critere_Etudiant[]) => {
      this.criteres = data;
      this.selectedCritereId = this.criteres[0].critere_etudiant_id;
    })
  }

  private initClassementGeneral($event: any) {
    let stats_tableau;
    if ($event == -1) {
      this.stats_tableau = this.stats_services.filter((s) => {
        return s.niveau_id == null && s.critere_etudiant_id == null
      }).sort((s1, s2) => {
        if (s1.value < s2.value) {
          return 1
        } else if (s1.value > s2.value) {
          return -1
        }
        return 0;
      })
    } else if ($event != -1) {
      this.stats_tableau = this.stats_services.filter((s) => {
        return s.niveau_id == $event && s.critere_etudiant_id == null
      }).sort((s1, s2) => {
        if (s1.value < s2.value) {
          return 1
        } else if (s1.value > s2.value) {
          return -1
        }
        return 0;
      })
    }
    Utils.initializeDataTables('tableGenerale', 50, 4)
  }

  onSelectCritere(classementParCriteres: string, $event: any) {
    this.selectedCritereId = $event;
    this.initClassementParCriteres(this.selectedNiveauIdCriteres);
  }

  initClassementParCriteres(number: number) {
    this.selectedNiveauIdCriteres = number;
    const that = this;
    if (number == -1) {
      this.stats_tableau_criteres = this.stats_services.filter((s) => {
        return s.niveau_id == null && s.critere_etudiant_id == that.selectedCritereId
      }).sort((s1, s2) => {
        if (s1.value < s2.value) {
          return 1
        } else if (s1.value > s2.value) {
          return -1
        }
        return 0;
      })
    } else if (number != -1) {
      this.stats_tableau_criteres = this.stats_services.filter((s) => {
        return s.niveau_id == number && s.critere_etudiant_id == that.selectedCritereId

      }).sort((s1, s2) => {
        if (s1.value < s2.value) {
          return 1
        } else if (s1.value > s2.value) {
          return -1
        }
        return 0;
      })
    }
    Utils.initializeDataTables('tableCriteres', 50, 3)

  }

  initChartDetailsService(number: any) {
    this.selectedNiveauIdDetails = number;
    //console.log(this.selectedServiceIdDetails);
    //console.log(number);
    const that = this;
    if (number == -1) {
      this.dataDetailsService = this.stats_services.filter((s) => {
        return s.niveau_id == null && s.service_id == that.selectedServiceIdDetails && s.critere_etudiant_id != null;
      }).map((s) => {
        return new Adata(s.critere_etudiant.label, s.value)
      });

    } else if (number != -1) {
      this.dataDetailsService = this.stats_services.filter((s) => {
        return s.niveau_id == number && s.service_id == that.selectedServiceIdDetails && s.critere_etudiant_id != null;
      }).map((s) => {
        return new Adata(s.critere_etudiant.label, s.value)
      });
    }
  }

  onSelectService(code: string, $event: any) {
    this.selectedServiceIdDetails = $event;
    this.initChartDetailsService(this.selectedNiveauIdDetails);
  }

  refreshStats() {
    const that = this;
    that.loaded=false;

    this.statsService.refreshAllStats().subscribe((data: StatService[]) => {
      this.stats_services = data;
      this.came_from_refresh = true;
      setTimeout(function () {
        that.getAllNiveaux();
        that.getAllServicesHavingStats();
        that.loaded=true;
      }, 100)
    })
  }

  getAllNumbers() {
    this.statsService.getNumbers().subscribe((data) => {
      this.number_evaluations = data['total_number_evals'];
      this.number_etudiants = data['number_etudiants'];
      if (this.services.length && this.services.length != 0)
        this.number_evals_services = Math.floor(this.number_evaluations / this.services.length);
    });
  }

  getCommentsByServiceAndLevel(service_id, niveau_id) {
    this.statsService.getCommentsByServiceAndLevel(service_id, niveau_id).subscribe((data:Evaluation[]) => {
      jQuery('.modal_comments').modal();
      this.evaluations =data;

    });

  }

  openCommentsModal(index) {

    let niveau_id = null;
    if (this.selectedNiveauIdClassementGeneral != -1)
      niveau_id = this.selectedNiveauIdClassementGeneral;

    this.router.navigate(['/statistiques-services/evaluations-etudiants'],
      { queryParams: { service_id: this.stats_tableau[index].service_id,niveau_id:niveau_id } });

    // this.selectedService = this.stats_tableau[index].service;
    this.getCommentsByServiceAndLevel(this.stats_tableau[index].service_id, niveau_id);
  }
}

class Adata implements DataModel {
  x: string;
  y: number;

  constructor(a, b) {
    this.x = a;
    this.y = b;
  }
}
class Comment{
  commentaire_etudiant : string;
}


