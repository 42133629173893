import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {CoordinatorService} from "../../shared/services/coordinator.service";
import {Service} from "../../shared/models/Service";
import {ServiceService} from "../../shared/services/service.service";
import {Utils} from "../../shared/utils";

@Component({
  selector: 'app-list-coordinator',
  templateUrl: './list-coordinator.component.html',
  styleUrls: ['./list-coordinator.component.css']
})
export class ListCoordinatorComponent implements OnInit, AfterViewInit {

  busy: Subscription;
  services: Service[] = [];

  constructor(private coordinatorService: CoordinatorService, private serviceService: ServiceService) {
  }

  ngOnInit() {

    this.getAllServices();
  }

  private getAllServices() {
    this.serviceService.getAll()
      .subscribe(
        (data: Service[]) => {
          this.services = data;
          Utils.initializeDataTables('list-service-table', 20, 3);
        }
      )
  }

  ngAfterViewInit() {
    const baseContext = this;
    this.serviceService.serviceListener = {
      onChangeService(services) {
        baseContext.services = services;
        Utils.initializeDataTables('list-service-table', 20, 3);
      }
    }
  }
}
