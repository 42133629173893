/**
 * Created by Abbes on 14/06/2017.
 */
/**
 * Created by Abbes on 13/06/2017.
 */
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Credentials} from "../shared/models/credentials";
import {AuthService} from "../shared/services/auth.service";
import {StorageService} from "../shared/services/storage.service";
import {Subscription} from "rxjs/index";

declare var jQuery: any;

@Component({
  templateUrl: './login.component.html',
  styleUrls: [],

})
export class LoginComponent implements OnInit {


  credentials: Credentials = new Credentials();
  isLoading: boolean;

  busy: Subscription;

  ngOnInit() {
    // jQuery(".alert").alert('close');
    jQuery(".alert").hide();
  }

  constructor(private authService: AuthService,
              private stoarageService: StorageService,
              private router: Router) {


  }

  loginSubmit() {
    this.isLoading = true;
    jQuery(".alert").hide();
    this.credentials.password = this.credentials.password.split(' ').join('');
    this.busy = this.authService.login(this.credentials)
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          this.stoarageService.write(StorageService.adminKey, data.admin);
          this.authService.loggedAdmin = data.admin;
          this.stoarageService.write(StorageService.tokenAdminKey, data.token);
          const baseContext = this;
          setTimeout(function () {
            baseContext.router.navigate(["/"], {queryParams: {reload: true}});
          }, 1000);
        },
        (error) => {
          this.isLoading = false;
          jQuery(".alert").show();
        }
      )
  }


}


