import {AfterViewInit, Component, DoCheck, EventEmitter, Input, IterableDiffers, OnInit, Output} from '@angular/core';
import {Utils} from "../../utils";

declare var jQuery: any;

@Component({
  selector: 'app-ng-select2',
  templateUrl: './ng-select2.component.html',
  styleUrls: ['./ng-select2.component.css']
})
export class NgSelect2Component implements OnInit, DoCheck, AfterViewInit {

  @Input()
  defaultValue?: string;

  @Input()
  objects: any;

  @Input()
  stringOption: any;

  @Input()
  idOption: string;

  options: string[] = [];
  ids: any[] = [];

  selected: any; // un élement ou plusieurs (select multiple ou pas)

  className: string;

  @Output()
  selectionChange: EventEmitter<any>;

  iterableDiffer: any;

  constructor(private _iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    this.selectionChange = new EventEmitter<any>();
    this.className = Utils.getRandomString();
  }

  ngOnInit() {
    const baseContext = this;


  }

  ngAfterViewInit() {
    const baseContext = this;
    Utils.initSelect(baseContext.className);
    jQuery('.' + baseContext.className).on('change', function () {
      baseContext.selected = jQuery(this).val();
      baseContext.selectionChange.emit(baseContext.selected);
    });
  }

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    Utils.setValuesjQueryCmp(this.className, value, 50);
    this.selected = value;
  }

  transformData() {
    const baseContext = this;
    if (baseContext.stringOption && baseContext.idOption) {
      if (baseContext.stringOption.constructor === String) {
        this.options = this.objects
          .map(object => {
            return object[baseContext.stringOption];
          });
      } else {
        baseContext.stringOption.forEach((s, index) => {
          const options = this.objects
            .map(object => {
              return object[s];
            });
          if (index === 0) {
            this.options = options;
          } else {
            this.options = options.map((object, index) => {
              return this.options[index] + " " + object;
            });
          }
        });
      }


      this.ids = this.objects
        .map(object => {
          return object[baseContext.idOption];
        });
    } else {
      this.options = this.objects;
      this.ids = this.objects;
    }
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.objects);
    if (changes) {
      this.transformData();
    }
  }

  setValue(value) {
    Utils.setValuesjQueryCmp(this.className, value, 50);
    this.selected = value;
  }


}
