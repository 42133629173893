import {Enseignant} from "./Enseignant";

export class Coordinator {
  public coordinator_id: number;
  public service_id: number;
  public niveau_id: number;
  public enseignant: Enseignant = new Enseignant();
}

export class CoordinatorRequest {
  public service_id: number;
  public niveau_id: number;
  public enseignantIds: number[] = [];
}
