import {NgModule} from '@angular/core';

import {ManageCoordinatorRoutingModule} from './manage-coordinator-routing.module';
import {ListCoordinatorComponent} from './list-coordinator/list-coordinator.component';
import {SharedModule} from "../shared/shared.module";
import { AddCoordinatorComponent } from './add-coordinator/add-coordinator.component';

@NgModule({
  imports: [
    SharedModule,
    ManageCoordinatorRoutingModule
  ],
  declarations: [ListCoordinatorComponent, AddCoordinatorComponent]
})
export class ManageCoordinatorModule {
}
