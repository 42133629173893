import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from "../shared/services/storage.service";
import {AuthService} from "../shared/services/auth.service";
import {Admin} from "../shared/models/Admin";

declare var jQuery: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
  components: NavigationMain[] = [];
  admin: Admin;

  constructor(private stoarageService: StorageService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService) {

    this.adminConnect();

  }

  adminConnect() {
    this.admin = this.authService.loggedAdmin;
    if (!this.admin) {
      this.admin = new Admin();
    }
    this.authService.getLoggedAdmin().subscribe(data => {
      this.admin = this.authService.loggedAdmin = data.admin;
      this.stoarageService.write(StorageService.adminKey, data.admin);
    }, error => {
      console.log(error);
      if (error.status === 401) {
        this.stoarageService.removeAll();
        this.router.navigateByUrl('login');
      }
    });
  }

  ngOnInit() {
    this.components = [
      {
        name: "Gestion des Sessions de Stages",
        icon: "icon-graduation2",
        visible: true,
        childrens: [
          {
            name: "List des sessions de stage",
            url: "/session-stage/list"
          },
          {
            name: "Ajout une session de stage",
            url: "/session-stage/add"
          },
        ]
      },
      {
        name: "Coordinateurs de stage",
        icon: "icon-users2",
        visible: true,
        url: "/manage-coordinator"
      },
      {
        name: "Gestion des étudiants",
        icon: "icon-users2",
        visible: true,
        url: "/manage-student/list-students"
      },
      {
        name: "Gestion des Evaluations",
        icon: "icon-address-book",
        visible: true,
        childrens: [
          {
            name: "Evaluation Externe",
            url: "/manage-evaluation/externe"
          }
        ]
      },

      {
        name: "Gestion des Jurys",
        icon: "icon-address-book",
        visible: true,
        childrens: [
          {
            name: "Affectations Des Jurys",
            url: "/manage-jury/add"
          }
        ]
      },
      {
        name: "Gestion des Internes",
        icon: "icon-address-book",
        visible: true,
        childrens: [
          {
            name: "Délibération",
            url: "/manage-resultats-internes/deliberation"
          }
        ]
      },
      {
        name: "Evaluations des Services",
        icon: "icon-address-book",
        visible: true,
        url: "/statistiques-services/global"
      }
    ];


    this.route.queryParams.subscribe(
      params => {
        console.log(params.reload);
        if (params.reload) {
          window.location.href = "/";
        }

      }
    );
    this.changeActiveUrl(this.router.url);

  }

  goUrl(url: string) {
    console.log("url + ", url);
    if (url) {
      this.router.navigate([url]);
    }
  }

  changeActiveUrl(url: string) {
    console.log(url);
    this.components.forEach(
      component => {
        component.active = "";
        if (url.indexOf(component.url) !== -1) {
          component.active = "active";
        }
        if (component.childrens) {
          component.childrens.forEach(
            child => {
              child.active = "";
              if (url.indexOf(child.url) !== -1) {
                child.active = "active";
              }
            }
          )
        }
      }
    )
  }

  logout() {
    this.stoarageService.removeAll();
    this.router.navigateByUrl("/login");
  }


}

export class NavigationMain {
  public name: string;
  public icon: string;
  public active?: string;
  public childrens?: ChildrenNavigation[] = [];
  public url?: string;
  public visible?: boolean;
  public notification?: number;
}

export class ChildrenNavigation {
  public name: string;
  public active?: string;
  public url?: string;
  public notification?: number;
  public action?: any;
  public hidden?: boolean;
}
