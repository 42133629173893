export class Enseignant {
  public enseignant_id: number;
  public CIN: string;
  public nom: string;
  public prenom: string;
  public email: string;
  public qr_code: string;
  public service_id: number;

}
