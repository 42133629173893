import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ListStudentsComponent} from "./list-students/list-students.component";

const routes: Routes = [
  {
    path: 'list-students',
    component: ListStudentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageStudentsRoutingModule {
}
