import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {StorageService} from './services/storage.service';
import {NgBusyModule} from "ng-busy";
import {ImportNoteComponent} from "../manage-ecos/import-note/import-note.component";
import {NgSelect2Component} from "./components/ng-select2/ng-select2.component";
import {NgDatepickerComponent} from "./components/ng-datepicker/ng-datepicker.component";
import {NgEmogiComponent} from "./components/ng-emogi/ng-emogi.component";
import {NgNbdocumentsComponent} from "./components/ng-nbdocuments/ng-nbdocuments.component";
import {NgValidPortfolioComponent} from "./components/ng-valid-portfolio/ng-valid-portfolio.component";
import {RoundPipe} from "./pipes/round.pipe";
import {NgRadioboxComponent} from "./components/ng-radiobox/ng-radiobox.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgBusyModule

  ],
  exports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    ImportNoteComponent,
    NgSelect2Component,
    NgDatepickerComponent,
    NgEmogiComponent,
    NgNbdocumentsComponent,
    NgValidPortfolioComponent,
    RoundPipe,
    NgRadioboxComponent
  ],
  declarations: [
    ImportNoteComponent,
    NgSelect2Component,
    NgDatepickerComponent,
    NgEmogiComponent,
    NgNbdocumentsComponent,
    NgValidPortfolioComponent,
    RoundPipe,
    NgRadioboxComponent
  ],
  providers: [StorageService]
})
export class SharedModule {

}
