import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionStageService} from "../../shared/services/session-stage.service";
import {SharedService} from "../../shared/services/shared.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Groupe} from "../../shared/models/Groupe";
import {Utils} from "../../shared/utils";
import {Periode} from "../../shared/models/Periode";
import {Subscription} from "rxjs";
import {ResultGroupe} from "../../shared/models/ResultGroupe";
import {Etudiant} from "../../shared/models/Etudiant";
import {EcosService} from "../../shared/services/ecos.service";
import {CompetanceClinique} from "../../shared/models/CompetanceClinique";
import {PortfolioService} from "../../shared/services/portfolio.service";
import {CriterePortfolio} from "../../shared/models/CriterePortfolio";

declare let swal: any;

@Component({
  selector: 'app-list-results',
  templateUrl: './list-results.component.html',
  styleUrls: ['./list-results.component.css']
})
export class ListResultsComponent implements OnInit {
  sessionStageId: number;
  selectedGroupeId: number;
  groupes: Groupe[] = [];
  selectedGroupe: Groupe;
  selectedPeriodeId: any;
  periodes: Periode[] | any = [];
  busy: Subscription;
  today = new Date();
  result_groupes: ResultGroupe[] = [];
  selectedEtudiantId: number;
  selectedEtudiant: Etudiant;
  selectedPeriode: Periode;
  resultGroupes: ResultGroupe[] = [];
  array_rows: Array_Row[] = [];
  @ViewChild('selectGroupeComponent') selectGroupeComponent;
  @ViewChild('selectPeriodeComponent') selectPeriodeComponent;

  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];

  constructor(private sessionStageService: SessionStageService,
              private sharedService: SharedService,
              private ecosService: EcosService,
              private portfolioService: PortfolioService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('session_stage_id'), 10);

    this.getAllGCompetances();
    this.getAllCriteresPortfolio();
    this.getPeriodesGroupes();
  }


  getAllGCompetances() {
    this.busy = this.ecosService.getAllCompetancesClinique()
      .subscribe(
        (data) => {
          this.criteresEcos = data;
        }
      )
  }

  getAllCriteresPortfolio() {
    this.busy = this.portfolioService.getAllPortfolioCriteres()
      .subscribe(
        (data) => {
          this.criteresPortfolio = data;
        }
      )
  }


  getPeriodesGroupes() {
    const baseContext = this;
    this.busy = this.sessionStageService.getPeriodesGroups(this.sessionStageId)
      .subscribe(
        (data: any) => {
          // data : periodes , groupes
          this.periodes = data.periodes;
          this.periodes.forEach((periode, i) => {
            i += 1;
            periode.label = "Période " + i;
          });
          this.selectedPeriode = this.periodes.find(p => {
            return (new Date(p.start_date) <= baseContext.today) && (new Date(p.end_date) >= baseContext.today)
          });
          if (!this.selectedPeriode) {
            this.selectedPeriode = this.periodes[this.periodes.length - 1];
          }
          this.selectedPeriodeId = this.selectedPeriode.periode_id;
          this.groupes = data.groupes;
          this.selectedGroupeId = this.groupes[1].groupe_id;

          if (this.sessionStageService.isEmptyFilter()) {
            this.sessionStageService.settingFilter(this.selectedGroupeId, this.selectedPeriodeId);
          } else {
            this.selectedGroupeId = this.sessionStageService.filterResult.groupId;
            this.selectedPeriodeId = this.sessionStageService.filterResult.periodeId;
          }
          this.selectGroupeComponent.setValue(this.selectedGroupeId);
          this.selectPeriodeComponent.setValue(this.selectedPeriodeId);

          this.selectedGroupe = this.groupes[1];
          this.array_rows = [];
          this.selectedGroupe.nomsJurys = this.selectedGroupe.jurys.map(jury => jury.nom + " " + jury.prenom).join(" - ");

          for (const critere_level of this.selectedGroupe.session_stage.niveau.criteres_niveaux) {
            const ar = new Array_Row();
            ar.label_critere = critere_level.critere.label;
            ar.critere_id = critere_level.critere.critere_id;
            ar.eval_jury = 0;
            ar.ponderation = critere_level.ponderation;
            this.array_rows.push(ar);
          }
          // console.log(this.periodes);
          // console.log(this.groupes);
          // this.get

          this.getResultGroupesByGroupeAndByPeriode(this.selectedGroupeId, this.selectedPeriodeId);
        }
      )
  }

  getResultGroupesByGroupeAndByPeriode(groupe_id: number, $event: any) {
    this.busy = this.sessionStageService.getResultGroupesByGroupeAndByPeriode(groupe_id, $event).subscribe(
      (data) => {
        this.result_groupes = data;
        // this.array_rows = [];
        // this.getAllResultGroupesByGroupe($event);
        let i = 0;
        this.selectedGroupe.speriode_labels = [];
        this.selectedGroupe.session_stage.periodes.forEach(periode => {
          i += 1;
          periode.label = "Période " + i;
          // periode.no_speriodes = periode.evaluations.length;
          // let j = 1;
          //
          // if(periode.no_speriodes==1)
          //   this.selectedGroupe.speriode_labels.push(periode.label);
          // else
          //   for(let speriode of periode.periodes_stages[0].speriodes){
          //     this.selectedGroupe.speriode_labels.push("S-Pér. "+j);
          //     j+=1;
          //   }
        });
        Utils.initializeDataTables("datatable-basic", 20, 5, 50)
        // this.result_groupes = data;
        // this.student_aliases = [];
        // for (let i = 1; i < number; i += 1) {
        //   this.student_aliases.push("Etudiant " + i);
        // }
        // Utils.initializeDataTables(20, 3);
        // //console.log(data);

      }
    );
  }

  onSelectGroupe($event: number) {
    // console.log("okay");
    if ($event !== this.selectedGroupeId) {
      this.selectedGroupeId = $event;
      // console.log($event);
      this.selectedGroupe = this.groupes.find(x => x.groupe_id == this.selectedGroupeId);
      this.selectedGroupe.nomsJurys = this.selectedGroupe.jurys.map(jury => jury.nom + " " + jury.prenom).join(" - ");
      // console.log(this.groupes);
      // console.log(this.selectedGroupe);
      this.array_rows = [];
      for (const critere_level of this.selectedGroupe.session_stage.niveau.criteres_niveaux) {
        const ar = new Array_Row();
        ar.label_critere = critere_level.critere.label;
        ar.critere_id = critere_level.critere.critere_id;
        ar.eval_jury = 0;
        ar.ponderation = critere_level.ponderation;
        this.array_rows.push(ar);
      }
      this.sessionStageService.settingFilter(this.selectedGroupeId, this.selectedPeriodeId);
      this.getResultGroupesByGroupeAndByPeriode(this.selectedGroupeId, this.selectedPeriodeId);
    }
  }

  onSelectPeriode($event: number) {
    this.selectedPeriodeId = $event;
    this.selectedPeriode = this.selectedGroupe.session_stage.periodes.find(x => x.periode_id == $event);
    this.sessionStageService.settingFilter(this.selectedGroupeId, this.selectedPeriodeId);
    this.getResultGroupesByGroupeAndByPeriode(this.selectedGroupeId, this.selectedPeriodeId);
  }

  details(i: number) {

    this.selectedEtudiantId = this.result_groupes[i].etudiant_id;
    this.selectedEtudiant = this.result_groupes[i].etudiant;
    this.sessionStageService.context = this;
    // console.log(this.array_rows);
    this.router.navigateByUrl('/manage-jury/list-results/' + this.sessionStageId + '/details-etudiant-result');
    // this.sessionStageService.getResultGroupesByStudentByIdAndGroupe(this.selectedGroupeId, this.result_groupes[i].etudiant_id)
    //   .subscribe((data) => {
    //     console.log(data);
    //   })
  }

  ignore(i: number) {
    const baseContext = this;
    // baseContext.busy = baseContext.sessionStageService.ignoreDecisionResultGroupe(baseContext.result_groupes[i]
    //   .result_groupe_id).subscribe((data) => {
    //   baseContext.result_groupes[i].decision = 0;
    //   baseContext.result_groupes[i].commentaire = "";
    // });
    /*
    swal({
      title: 'Vous etes sur?',
      text: "Cette opération est irreversible.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }).then(function (isConfirm) {
      if (isConfirm) {
        baseContext.busy = baseContext.sessionStageService.ignoreDecisionResultGroupe(baseContext.result_groupes[i]
          .result_groupe_id).subscribe((data) => {
          baseContext.result_groupes[i].decision = 0;
          baseContext.result_groupes[i].commentaire = "";
        }, (error) => {
          swal({
            title: "Erreur !",
            text: 'impossible',
            type: "danger"
          });
        });
      }
    })*/
    swal({
      title: 'Êtes-vous sûrs?',
      text: "Cette action est irreversible!",
      icon: 'warning',
      buttons: ['Annuler', 'Confirmer'],
      dangerMode: true,
    }).then((result) => {
      if (result) {

        baseContext.busy = baseContext.sessionStageService.ignoreDecisionResultGroupe(baseContext.result_groupes[i]
          .result_groupe_id).subscribe((data) => {
          baseContext.result_groupes[i].decision = 0;
          baseContext.result_groupes[i].commentaire = "";
          swal(
            'Succès!',
            'Décision ignorée',
            'success'
          )
        }, (error) => {
          swal(
            'Erreur!',
            'Une erreur c\'est produite',
            'error'
          )
        });
      }
    })

  }

  ignoreGroupePeriode() {

    swal({
      title: 'Êtes-vous sûrs?',
      text: "Cette action est irreversible!",
      icon: 'warning',
      buttons: ['Annuler', 'Confirmer'],
      dangerMode: true,
    }).then((result) => {
      if (result) {


        this.busy = this.sessionStageService.ignoreDecisionGroupePeriode(this.selectedGroupeId, this.selectedPeriodeId)
          .subscribe((data) => {
              this.result_groupes.forEach(resultGroupe => {
                resultGroupe.decision = 0;
                resultGroupe.commentaire = "";
              });
              swal(
                'Succès!',
                'Décision ignorée',
                'success'
              )
            }
            , (error) => {
              swal(
                'Erreur!',
                'Une erreur c\'est produite',
                'error'
              )
            });
      }
    })
  }
}

class Array_Row {
  label_critere = "";
  critere_id = 0;
  ponderation = 0;
  values = [];
  eval_jury: number;
}
