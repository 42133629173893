import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddSessionStageComponent} from "./add-session-stage/add-session-stage.component";
import {ListSessionStageComponent} from "./list-session-stage/list-session-stage.component";
import {ListAffectationComponent} from "./list-affectation/list-affectation.component";
import {TableauBoardComponent} from "./tableau-board/tableau-board.component";
import {AddAffectationComponent} from "./add-affectation/add-affectation.component";
import {EditEvaluationComponent} from "./edit-evaluation/edit-evaluation.component";
import {AffectSummerComponent} from "./affect-summer/affect-summer.component";
import {EditEcosComponent} from "./edit-ecos/edit-ecos.component";
import {EditPortfolioComponent} from "./edit-portfolio/edit-portfolio.component";

// Layouts
export const routes: Routes = [
  {
    path: ':sessionStageId/affect-summer',
    component: AffectSummerComponent
  },
  {
    path: 'add',
    component: AddSessionStageComponent
  },
  {
    path: 'list',
    component: ListSessionStageComponent
  },
  {
    path: ':sessionStageId/editEvaluation/:evaluationId',
    component: EditEvaluationComponent
  },
  {
    path: ':sessionStageId/editEcos/:evaluationId',
    component: EditEcosComponent
  },
  {
    path: ':sessionStageId/editPortfolio/:evaluationId',
    component: EditPortfolioComponent
  },
  {
    path: ':sessionStageId/edit',
    component: AddSessionStageComponent
  },
  {
    path: ':sessionStageId/list-affectation',
    component: ListAffectationComponent
  },
  {
    path: ':sessionStageId/tableau-board',
    component: TableauBoardComponent
  },
  {
    path: ':sessionStageId/manual-affectation',
    component: AddAffectationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SessionStageRouting {
}
