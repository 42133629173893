import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalStatsComponent } from './global-stats/global-stats.component';
import {SharedModule} from "../shared/shared.module";
import {D3Module} from "../d3/d3.module";
import {StatistiquesServicesRouting} from "./statistiques-services.routing";
import { EvaluationsEtudiantsComponent } from './evaluations-etudiants/evaluations-etudiants.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StatistiquesServicesRouting,
    D3Module
  ],
  declarations: [ GlobalStatsComponent, EvaluationsEtudiantsComponent]
})
export class StatistiquesServicesModule { }
