import {Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import {DataModel} from "../../interfaces/DataModel";
import {BaseType, ScaleBand, ScaleLinear} from "d3";


@Component({
  selector: 'app-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnChanges {
  @ViewChild('chart')
  private chartContainer: ElementRef;

  @Input()
  data: DataModel[] = [];
  @Input()
  numberTicks = 4;
  @Input()
  margin = {top: 40, right: 20, bottom: 40, left: 40};
  @Input()
  xLabel = "";
  @Input()
  yLabel = "";
  @Input()
  title = "";
  @Input()
  x_unite = "";
  @Input()
  y_unite = "";

  constructor() {
  }

  ngOnChanges(): void {
    if (!this.data) {
      return;
    }

    this.createChart();
  }

  private createChart(): void {
    // d3.select('svg').remove();

    // Getting the size of the containing html element of the component
    const element = this.chartContainer.nativeElement;
    const data = this.data;
    // Adding an svg element and setting its size
    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);
    // Calculating the content height and width based on the outer size and the margins
    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;
    const x = BarChartComponent.createXScale(contentWidth, data);
    const y = BarChartComponent.createYScale(contentHeight, data);
    // Creating a group for the bars, translated with the respective left and top margins
    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    this.addAxis(g, x, y, contentHeight);
    this.addXlabel(svg, contentWidth, contentHeight);
    this.addYlabel(svg, contentWidth, contentHeight);
    this.addTitle(svg, contentWidth, contentHeight);

    var tooltip = d3.select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#555")
      .style("color", "#FFFFFF")
      .style("padding", "4px")
      .style("border-radius", "10px");
    this.addBars(g, x, y, data, contentHeight, tooltip);


  }

  addXlabel(svg, contentWidth, contentHeight) {// Adding x label
    if (this.xLabel !== "") {
      svg.append('text')
        .attr('x', contentWidth / 2 + this.margin.left)
        .attr('y', contentHeight + this.margin.top + this.margin.bottom / 2)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'middle')
        .text(this.xLabel);
    }
  }

  addYlabel(svg, contentWidth, contentHeight) {// Adding x label
    if (this.yLabel !== "") {
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -contentHeight / 2 - this.margin.top)
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'middle')
        .text(this.yLabel);
    }
  }

  static createXScale(contentWidth: number, data) {
    return d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(data.map(d => d.x));
  }

  static createYScale(contentHeight: number, data: DataModel[]) {
    return d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, d3.max(data, d => d.y)]);
  }

  addAxis(g, x, y, contentHeight) {

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x));
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).ticks(this.numberTicks));
  }

  addBars(g, x, y, data, contentHeight, tooltip) {
    const that = this;

    g.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.x))
      .attr('y', d => y(d.y))
      .attr('width', x.bandwidth())
      .attr('height', d => contentHeight - y(d.y))
      .on("mouseover", function (d) {
        let text="";
        if (that.y_unite != "") {
          if (d.y > 1)
            text= d.y + " " + that.y_unite + "s";
          else
          text= d.y + " " + that.y_unite;
        }
        tooltip.text(text);
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", function () {
        return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("visibility", "hidden");
      });


  }

  addTitle(svg, contentWidth, contentHeight) {
    if (this.title !== "") {
      svg.append('text')
        .attr('x', contentWidth / 2 + this.margin.left)
        .attr('y', this.margin.top / 3)
        .attr('dy', '1.25em')
        .attr('text-anchor', 'middle')
        .text(this.title);
    }
  }
}
