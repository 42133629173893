import {S_Groupe} from "./S_Groupe";
import {Enseignant} from "./Enseignant";
import {Session_Stage} from "./Session_Stage";

export class Groupe {
  public groupe_id: number;
  public label: string;

  public capacity: number;
  public session_stage_id: number;

  public sgroupes: S_Groupe [] = [];
  public nb_sg: string;

  public jury: Enseignant;
  public jury_id: Enseignant;
  public nomJury: string;

  public no_students: number;
  public session_stage: Session_Stage;
  public speriode_labels: any[] = [];
  public jurys: Enseignant[] = [];
  public nomsJurys = "";
}
