import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {GenericService} from "./generic.service";
import {ObjectSended} from "../../manage-ecos/import-note/import-note.component";
import {environment} from "../../../environments/environment";
import {CompetanceClinique} from "../models/CompetanceClinique";

@Injectable({
  providedIn: 'root'
})
export class EcosService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  importNote(data: ObjectSended) {
    const url = environment.baseUrl + '/ecos/import-note';

    return this.http.post(url, data);
  }

  getAllCompetancesClinique() {
    const url = environment.baseUrl + "/ecos/competance-clinique/all";
    return this.http.get<CompetanceClinique[]>(url);
  }

  getAllGCompetances() {
    const url = environment.baseUrl + "/ecos/gcompetance-clinique/all";
    return this.http.get<any>(url);
  }

  editEvaluationEcos(evaluationId, studentId, gcompetances) {
    const url = environment.baseUrl + "/ecos/evaluation/" + evaluationId + "/student/" + studentId + "/edit";
    return this.http.put<any>(url, gcompetances);
  }
}
