import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ListCoordinatorComponent} from "./list-coordinator/list-coordinator.component";

const routes: Routes = [
  {
    path: "",
    component: ListCoordinatorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageCoordinatorRoutingModule {
}
