import {Periode} from "./Periode";
import {S_Groupe} from "./S_Groupe";

export class S_Periode {
  public s_periode_id: number;
  public periode_id: number;

  public start_date: string;
  public end_date: string;

  public end_middle_date?: string;
  public start_middle_date?: string;

  public periode: Periode = new Periode();
  public s_groupe_id: number;
  public sgroupe: S_Groupe = new S_Groupe();


}
