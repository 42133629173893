import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {GenericService} from "./generic.service";
import {environment} from "../../../environments/environment";
import {AffectSummerRequest} from "../models/affect-summer.request";

@Injectable({
  providedIn: 'root'
})
export class SummerAffectService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getSummerPeriodBySessionId(sessionId: number) {
    const url = environment.baseUrl + "/session-stage/" + sessionId + "/period-summer";
    return this.http.get(url);
  }

  affectSummerBySessionId(sessionId, affectRequest: AffectSummerRequest) {
    const url = environment.baseUrl + "/session-stage/" + sessionId + "/affect-period-summer";
    return this.http.post(url, affectRequest);
  }
}
