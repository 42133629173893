import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BarChartComponent} from "./components/bar-chart/bar-chart.component";
import {HBarChartComponent} from './components/h-bar-chart/h-bar-chart.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    BarChartComponent, HBarChartComponent
  ]
  ,
  declarations: [BarChartComponent, HBarChartComponent]
})
export class D3Module {
}
