export class AffectSummerRequest {
  start_date: string;
  end_date: string;
  students: ItemStudentAffectRequest[] = [];
}

export class ItemStudentAffectRequest {
  service: string;
  students: {
    cin: string,
    first_name: string,
    last_name: string
  }[]
}

export class ExcelDataSummer {
  first_name: string;
  last_name: string;
  cin: string;
  service: string;
}
