import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SessionStageService} from "../../shared/services/session-stage.service";
import {SharedService} from "../../shared/services/shared.service";
import {Etudiant} from "../../shared/models/Etudiant";
import {Utils} from "../../shared/utils";
import {Session_Stage} from "../../shared/models/Session_Stage";
import {Subscribable, Subscription} from "rxjs";
import {ResultInterne} from "../../shared/models/ResultInterne";
import {forEach} from "@angular/router/src/utils/collection";
import {Service} from "../../shared/models/Service";
import {ServiceService} from "../../shared/services/service.service";
import {ResultServiceInterne} from "../../shared/models/ResultServiceInterne";
import {NgSelect2Component} from "../../shared/components/ng-select2/ng-select2.component";

declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-deliberation-resultats-internes',
  templateUrl: './deliberation-resultats-internes.component.html',
  styleUrls: ['./deliberation-resultats-internes.component.css']
})
export class DeliberationResultatsInternesComponent implements OnInit {
  public selected_level_id = 6;
  public selected_session_stage_id = 6;
  etudiants: Etudiant[] = [];
  session_stage: Session_Stage;
  busy: Subscription;
  selectedEtudiant: Etudiant;
  doneEverything = true;
  services: Service[] = [];
  @ViewChildren(NgSelect2Component) serviceSelects: QueryList<NgSelect2Component>;
  etudiantsNonValides: Etudiant[];

  constructor(private sessionStageService: SessionStageService,
              private sharedService: SharedService,
              private serviceService: ServiceService) {
  }

  ngOnInit() {
    this.getAllEtudiantsByLevelId(this.selected_level_id);
    this.getSessionStageById(this.selected_session_stage_id);
    this.getAllServices();
    const baseContext = this;
    // TODO fix this
  }

  private getAllEtudiantsByLevelId(level_id) {
    this.busy = this.sharedService.getStudentsByLevelId(level_id).subscribe(
      (data) => {
        this.etudiants = data;
        const table = jQuery('.dataTable');
        // this.initDatatable(50);
        setTimeout(function () {
          table.DataTable({
            dom: 'Bfrtip',
            language: {
              "emptyTable": "..."
            },
            buttons: {
              dom: {
                button: {
                  className: 'btn btn-default'
                }
              },
              buttons: [
                'excelHtml5',
                'pdfHtml5'
              ]
            }
            ,
            "columnDefs": [{
              "targets": 3,
              "searchable": false
            }]
          });
        }, 100)

        // Utils.initializeDataTables('dataTable', 60, 4);
      });
  }

  private getSessionStageById(selected_session_stage_id: number) {
    this.busy = this.sessionStageService.getSessionStageById(selected_session_stage_id).subscribe((data: Session_Stage) => {
      this.session_stage = data;
    })
  }

  openModal(i: number) {

    const that = this;
    // that.selectedEtudiant = null;
    that.selectedEtudiant = that.etudiants[i];
    this.doneEverything = true;
    if (this.selectedEtudiant.result_interne == null) {
      this.selectedEtudiant.result_interne = new ResultInterne();
      this.selectedEtudiant.result_interne.etudiant_id = this.selectedEtudiant.etudiant_id;
      this.selectedEtudiant.result_interne.decision = 0;
      this.selectedEtudiant.result_interne.session_stage_id = this.session_stage.session_stage_id;
      this.selectedEtudiant.result_interne.results_services_internes = [];
      for (const periode of this.session_stage.periodes) {
        const result_session = new ResultServiceInterne();
        result_session.decision = 0;
        result_session.periode_id = periode.periode_id;
        result_session.service_id = null;
        this.selectedEtudiant.result_interne.results_services_internes.push(result_session);
      }
      // setTimeout(function () {
      //   that.serviceSelects.forEach((serviceSelect, index) => {
      //       console.log("Awesome (not really)");
      //       // serviceSelect.selection = 5;
      //       serviceSelect.selection = 0;
      //       // console.log(serviceSelect);
      //     }
      //   );
      // }, 30);
    } else if (this.selectedEtudiant.result_interne.results_services_internes != null
      && this.selectedEtudiant.result_interne.results_services_internes.length > 0) {
      // // console.log("wut");
      // setTimeout(function () {
      //   that.serviceSelects.forEach((serviceSelect, index) => {
      //       serviceSelect.selection = that.selectedEtudiant.result_interne.results_services_internes[index].service_id;
      //       // console.log(serviceSelect);
      //     }
      //   );
      // }, 20);
    } else if (this.selectedEtudiant.result_interne.results_services_internes.length === 0) {
      this.selectedEtudiant.result_interne.results_services_internes = [];
      for (const periode of this.session_stage.periodes) {
        const result_session = new ResultServiceInterne();
        result_session.decision = 1;
        result_session.periode_id = periode.periode_id;
        result_session.service_id = null;
        this.selectedEtudiant.result_interne.results_services_internes.push(result_session);
      }
      // setTimeout(function () {
      //   that.serviceSelects.forEach((serviceSelect, index) => {
      //       serviceSelect.selection = that.selectedEtudiant.result_interne.results_services_internes[index].service_id;
      //       // console.log(serviceSelect);
      //     }
      //   );
      // }, 20);
    }
    // setTimeout(function () {
    //   jQuery('.modal').on('hidden.bs.modal', function () {
    //     that.selectedEtudiant = null;
    //     // console.log(that.selectedEtudiant);
    //     // console.log("come on");
    //   });
    // }, 100);
  }

// TODO FIX RESET WHEN HIDDEN
  deciderPeriode(i: any, number: number) {
    this.selectedEtudiant.result_interne.results_services_internes[i].decision = number;
  }

  getAllServices() {
    this.busy = this.serviceService.getAll().subscribe((data: Service[]) => {
      this.services = data;
    });
  }

  confirmDecisionEtudiant(i: any) {
    let cestbon = true;
    for (const resultSession of this.selectedEtudiant.result_interne.results_services_internes) {
      if (resultSession.decision === 0 || resultSession.service_id == null || resultSession.periode_id == null) {
        this.doneEverything = false;
        return;
      }
      if (resultSession.decision === 2) {
        this.selectedEtudiant.result_interne.decision = 2;
        cestbon = false;
      }
    }
    if ((this.selectedEtudiant.result_interne.decision === 0) ||
      ((cestbon) && (this.selectedEtudiant.result_interne.decision === 2))) {
      this.selectedEtudiant.result_interne.decision = 1;
    }
    if (this.selectedEtudiant.result_interne.result_interne_id != null) {
      this.sessionStageService.editResultInterne(this.selectedEtudiant.result_interne).subscribe((data) => {
        this.selectedEtudiant.result_interne = data.result_interne;
        this.selectedEtudiant = null;
      });
    } else {
      this.sessionStageService.decideResultInterne(this.selectedEtudiant.result_interne).subscribe((data) => {
        this.selectedEtudiant.result_interne = data.result_interne;
        this.selectedEtudiant = null;
      });
    }
    this.doneEverything = true;
    jQuery('#myModal').modal('hide');
  }

  onSelectService(i: number, $event: any) {
    this.selectedEtudiant.result_interne.results_services_internes[i].service_id = $event;
  }

  closeModal() {
    this.selectedEtudiant = null;
  }

  delberationAutomatique() {
    this.etudiantsNonValides = this.etudiants.filter(etudiant => {
      return etudiant.result_interne && etudiant.result_interne.decision === 2
    });

  }

  closeModal2() {

  }

  confirmDeliberationAutomatique() {
    jQuery('#myModal2').modal('hide');
    this.busy = this.sessionStageService.deliberationAutomatique(this.session_stage.session_stage_id).subscribe((data: any) => {
      this.etudiants = data.etudiants;
      swal('Succès', 'Délibération faite avec succès.', 'success');
      const table = jQuery('.dataTable');
      table.DataTable().destroy();
      setTimeout(function () {
        table.DataTable({
          dom: 'Bfrtip',
          language: {
            "emptyTable": "..."
          },
          buttons: {
            dom: {
              button: {
                className: 'btn btn-default'
              }
            },
            buttons: [
              'excelHtml5',
              'pdfHtml5'
            ]
          }
          ,
          "columnDefs": [{
            "targets": 3,
            "searchable": false
          }]
        })
      }, 100);
      // table.DataTable().destroy();
      // this.initDatatable(1000);
      // setTimeout(function () {
      //   table.DataTable()
      // }, 6000);
      // Utils.initializeDataTables('dataTable', 8000, 4);
      // jQuery('.dataTable').DataTable();
    }, error => {
      swal('Erreur', 'Une erreur est survenue, veuillez réessayer.', 'error');
    })

  }

  openModal2() {
    this.etudiantsNonValides = this.etudiants.filter(etudiant => {
      return etudiant.result_interne && etudiant.result_interne.decision === 2
    });
    Utils.initializeDataTables('tableModal2', 30, 5);
  }

}
