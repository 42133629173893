import {Etudiant} from "./Etudiant";
import {Session_Stage} from "./Session_Stage";
import {ResultServiceInterne} from "./ResultServiceInterne";

export class ResultInterne {

  public result_interne_id: number;
  public decision: number;
  public etudiant_id: number;
  public session_stage_id: number;
  public etudiant: Etudiant;
  public session_stage: Session_Stage;
  public results_services_internes : ResultServiceInterne[];
  /* Lié au UI */
  noteEcosEditable: boolean;


}
