import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {AuthService} from "./services/auth.service";

declare var swal: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  ingnoredInterceptedUris = ['factoryUploadTmpDocuments'];

  constructor(private authService: AuthService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let interceptRequest = true;
    this.ingnoredInterceptedUris.forEach((function (ingnoredInterceptedUri) {
      interceptRequest = interceptRequest && !request.url.includes(ingnoredInterceptedUri);
    }));
    request = interceptRequest ? request.clone({
      setHeaders: this.authService.getToken() ? {
        Authorization: `Bearer ${this.authService.getToken()}`,
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*'
      } : {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*'
      }
    }) : request;
    return next.handle(request)
      .pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.clear();
            this.router.navigate(['login']);
            // swal('Warning', 'la session est expiré', 'warning');
          } else if (err.status > 299 || err.status < 200) {
            // swal('Warning', 'Une erreur est surv', 'error');
          }
        }
        return of(HttpErrorResponse);
      }))as Observable<HttpEvent<any>>;
  }
}
