import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataModel} from "../../interfaces/DataModel";
import * as d3 from "d3";
import {log} from "util";

@Component({
  selector: 'app-h-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './h-bar-chart.component.html',
  styleUrls: ['./h-bar-chart.component.scss']
})
export class HBarChartComponent implements OnChanges {
  @ViewChild('hchart')
  private chartContainer: ElementRef;

  @Input()
  data: DataModel[] = [];
  @Input()
  numberTicks = 4;
  @Input()
  margin = {top: 40, right: 20, bottom: 40, left: 150};
  @Input()
  xLabel = "";
  @Input()
  yLabel = "";
  @Input()
  title = "";
  @Input()
  x_unite = "";
  @Input()
  y_unite = "";
  @Input()
  sorted = false;
  @Input()
  max_x_scale = null;
  @Input()
  myname="hchart";
  @Input()
  manualOffset: number;

  constructor() {
  }

  ngOnChanges(): void {
    if (!this.data) {
      return;
    }
    if (this.sorted) {
      this.data.sort((a, b) => {
          if (a.y > b.y) {
            return -1
          } else if (a.y < b.y) {
            return 1
          } else {
            return 0
          }
        }
      )
    }

    this.createChart();
  }

  private createChart(): void {
    // d3.select('.'+this.myname).remove();
    // console.log(this.myname);
    // Getting the size of the containing html element of the component
    const element = this.chartContainer.nativeElement;
    const data = this.data;
    d3.select(element).select('svg').remove();
    // Adding an svg element and setting its size
    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);
    // Calculating the content height and width based on the outer size and the margins
    let contentWidth = element.offsetWidth - this.margin.left - this.margin.right-this.manualOffset;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;
    const x = this.createXScale(contentWidth, data);
    const y = HBarChartComponent.createYScale(contentHeight, data);
    // Creating a group for the bars, translated with the respective left and top margins
    const g = svg.append('g');
    this.addAxis(g, x, y, contentHeight);
    this.addXlabel(svg, contentWidth, contentHeight);
    this.addYlabel(svg, contentWidth, contentHeight);
    this.addTitle(svg, contentWidth, contentHeight);
    var tooltip = d3.select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#555")
      .style("color", "#FFFFFF")
      .style("padding", "4px")
      .style("border-radius", "10px");

    this.addBars(g, x, y, data, contentWidth, tooltip);
    const maxw = 0;

    g.selectAll('.axis--y').selectAll('text').each(function () {
      // @ts-ignore
      if (maxw < this.getBBox().width) maxw = this.getBBox().width;
    });
    this.margin.left=maxw+50;
    contentWidth = element.offsetWidth - this.margin.left - this.margin.right;

    g.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  addXlabel(svg, contentWidth, contentHeight) {// Adding x label
    if (this.xLabel !== "") {
      svg.append('text')
        .attr('x', contentWidth / 2 + this.margin.left)
        .attr('y', contentHeight + this.margin.top + this.margin.bottom / 2)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'middle')
        .text(this.xLabel);
    }
  }

  addYlabel(svg, contentWidth, contentHeight) {// Adding x label
    if (this.yLabel !== "") {
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -contentHeight / 2 - this.margin.top)
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'middle')
        .text(this.yLabel);
    }
  }

  static createYScale(contentWidth: number, data) {
    return d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(data.map(d => d.x));
  }

  createXScale(contentHeight: number, data: DataModel[]) {
    if(!this.max_x_scale)
    return d3
      .scaleLinear()
      .rangeRound([0, contentHeight])
      .domain([0, d3.max(data, d => d.y)]);
    return d3
      .scaleLinear()
      .rangeRound([0, contentHeight])
      .domain([0, this.max_x_scale]);
  }

  addAxis(g, x, y, contentHeight) {

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x).ticks(this.numberTicks));
    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y));
  }

  addBars(g, x, y, data, contentWidth, tooltip) {
    const that = this;
    g.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', 0)
      .attr('y', d => y(d.x))
      .attr('height', y.bandwidth())
      .attr('width', d => x(d.y))
      .on("mouseover", function (d) {
        let text = "";
        if (that.y_unite != "") {
          if (d.y > 1)
            text = d.y + " " + that.y_unite + "s";
          else
            text = d.y + " " + that.y_unite;
        } else {
          text = d.y;
        }
        tooltip.text(text);
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", function () {
        return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("visibility", "hidden");
      });


  }

  addTitle(svg, contentWidth, contentHeight) {
    if (this.title !== "") {
      svg.append('text')
        .attr('x', contentWidth / 2 + this.margin.left)
        .attr('y', this.margin.top / 3)
        .attr('dy', '1.25em')
        .attr('text-anchor', 'middle')
        .text(this.title);
    }
  }
}
