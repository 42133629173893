import {Component, OnInit} from '@angular/core';
import {Session_Stage} from "../../shared/models/Session_Stage";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {Utils} from "../../shared/utils";
import {Subscription} from "rxjs";
import * as FileSaver from "file-saver";
import {Router} from "@angular/router";
import * as XLSX from 'xlsx';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {Groupe} from "../../shared/models/Groupe";

declare let jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-list-session-stage',
  templateUrl: './list-session-stage.component.html',
  styleUrls: ['./list-session-stage.component.css']
})
export class ListSessionStageComponent implements OnInit {

  session_stages: Session_Stage[] = [];
  busy: Subscription;
  index: number;
  letter: string;
  pattern = "[a-zA-Z]";
  form = new FormGroup({
    input: new FormControl(''),
  });

  constructor(private sessionStageService: SessionStageService, private formBuilder: FormBuilder,
              private router: Router) {
    this.form = this.formBuilder.group({
      input: ['', [Validators.required, Validators.pattern(this.pattern)]],
    });
  }


  ngOnInit() {
    this.getAllSessionStage();
  }

  deleteSession(index) {
    // TODO Delete Session Stage
    const baseContext = this;

    swal({
      title: 'Attention !',
      text: 'Êtes-vous sûrs de vouloir supprimer la session de stage ? ',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Non annuler',
          value: null,
          visible: true
        },
        confirm: {
          text: 'Oui Supprimer',
          vaule: true,
          visible: true
        }
      }

    })
      .then((willDelete) => {
        if (willDelete) {
          baseContext.busy = baseContext.sessionStageService.delete(baseContext.session_stages[index].session_stage_id)
            .subscribe(
              (data) => {
                this.session_stages.splice(index, 1);
                Utils.initializeDataTables('session_stage_table', 20, 9);
                swal('Succeés', 'Commande supprimée avec suucées', 'success');
              }
            );
        }
      });


  }

  exportPDFResultChoixGroup(index: number) {
    this.busy = this.sessionStageService.generateResultGroupPDF(this.session_stages[index].session_stage_id)
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.session_stages[index].session_stage_id + "_choice_group.pdf");
        }
      )
  }

  exportPDFResultChoixService(index: number) {
    this.busy = this.sessionStageService.generateResultServicePDF(this.session_stages[index].session_stage_id)
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.session_stages[index].session_stage_id + "_choice_service.pdf");
        }
      )
  }

  getAllSessionStage() {
    this.busy = this.sessionStageService.getAllSessionStage()
      .subscribe(
        (data: Session_Stage[]) => {
          this.session_stages = data;
          Utils.initializeDataTables('session_stage_table', 20, 9);
        },
        (error) => {

        }
      )
  }

  delibChoixGroup(index: number) {
    this.busy = this.sessionStageService.delibrationGroupStage(this.session_stages[index].session_stage_id)
      .subscribe(
        (data) => {
          this.session_stages[index].status_group_choice = 1;
          swal('Succées', 'Délibration choix groupe avec succées', 'success');
        },
        (error) => {

        }
      )
  }

  delibChoixService(index: number) {


    this.busy = this.sessionStageService.delibrationServiceStage(this.session_stages[index].session_stage_id)
      .subscribe(
        (data) => {
          this.session_stages[index].status_service_choice = 1;
          swal('Succées', 'Délibration choix groupe avec succées', 'success');
        },
        (error) => {

        }
      )
  }

  affect() {
    if (!this.letter && this.session_stages[this.index].niveau_id !== 5) {
      return;
    }
    this.busy = this.sessionStageService.affectEtudiants(this.session_stages[this.index].session_stage_id, this.letter)
      .subscribe(
        (data) => {
          swal('Succées', 'Délibration choix est effectué avec succées', 'success');
          this.router.navigate(['/session-stage/' + this.session_stages[this.index].session_stage_id + '/list-affectation'])
          jQuery("#modal_form_vertical").modal('hide');
        },
        (error) => {
          swal('Erreur', 'Une erreur est survenu', 'error');
          jQuery("#modal_form_vertical").modal('hide');
        }
      )
  }

  openModelLettre(index: number) {
    this.index = index;
    if (this.session_stages[index].niveau_id === 3 || this.session_stages[index].niveau_id === 4) {
      jQuery("#modal_form_vertical").modal();
    } else {
      this.affect();
    }

  }

  private exportGroupeStudents(groupes: Groupe[], studentsGroupes: any) {


    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    for (let i = 0; i < groupes.length; i++) {
      let data = [["ID", "NOM", "PRENOM", "CIN/IDENT"]];
      for (let j = 0; j < studentsGroupes[i].length; j++) {
        data.push([
          studentsGroupes[i][j].etudiant.etudiant_id,
          studentsGroupes[i][j].etudiant.nom.toUpperCase(),
          studentsGroupes[i][j].etudiant.prenom,
          studentsGroupes[i][j].etudiant.CIN,
        ])
      }

      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
      /* generate workbook and add the worksheet */
      XLSX.utils.book_append_sheet(wb, ws, groupes[i].label);
    }
    /* save to file */
    XLSX.writeFile(wb, "GroupesStudents.xlsx");
  }

  generationStudentsGroupes(index) {

    this.busy = this.sessionStageService.getResultGroupesByPeriode(this.session_stages[index].periodes[0].periode_id)
      .subscribe(
        (data) => {
          console.log(data);

          data = data.sort(function (a, b) {
            if (a.etudiant.nom < b.etudiant.nom) {
              return -1;
            }
            if (a.etudiant.nom > b.etudiant.nom) {
              return 1;
            }
            return 0;
          });


          var datag: any = Utils.groupByM(data, function (item) {
            return [item.groupe_id];
          });


          datag.forEach(item => {
            item.sort(function (a, b) {
              if (a.etudiant.nom.toUpperCase() < b.etudiant.nom.toUpperCase()) {
                return -1;
              }
              if (a.etudiant.nom.toUpperCase() > b.etudiant.nom.toUpperCase()) {
                return 1;
              }
              return 0;
            });
          });
          console.log(datag);

          this.exportGroupeStudents(this.session_stages[index].groupes, datag);


        },
        (error) => {

        }
      )


  }

}
