import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Service} from "../../shared/models/Service";
import {Periode} from "../../shared/models/Periode";
import {S_Periode} from "../../shared/models/S_Periode";
import {Utils} from "../../shared/utils";
import {ServiceService} from "../../shared/services/service.service";
import {PeriodeService} from "../../shared/services/periode.service";
import {ActivatedRoute} from "@angular/router";
import {Etudiant} from "../../shared/models/Etudiant";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {AffectationItem, AffectationRequest} from "../../shared/models/AffectationRequest";
import {PeriodeStage} from "../../shared/models/PeriodeStage";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-add-affectation',
  templateUrl: './add-affectation.component.html',
  styleUrls: ['./add-affectation.component.css']
})
export class AddAffectationComponent implements OnInit {

  busy: Subscription;

  services: Service[] = [];


  periodes: Periode[] = [];
  speriodes: PeriodeStage[] = [];
  fixSperiodes: PeriodeStage[] = [];

  students: Etudiant[] = [];

  submitted: boolean;

  affectation: AffectationRequest = new AffectationRequest();

  sessionStageId: number;

  constructor(private serviceService: ServiceService,
              private periodeService: PeriodeService,
              private sessionStageService: SessionStageService,
              private route: ActivatedRoute) {
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'));
  }

  ngOnInit() {

    Utils.initSelect('select-student');
    this.getAllServices();
    this.getAllPeriodes();
    this.getAllSperiodes();
    this.getStudentsNotAffected();

    this.onChangeSelectStudent();

  }

  private onChangeSelectStudent() {
    const baseContext = this;
    const selectStudent = jQuery('.select-student');

    selectStudent.on('change', function () {
      baseContext.affectation.etudiant_id = parseInt(jQuery(this).val(), 0);
    });
  }

  private getStudentsNotAffected() {
    this.sessionStageService.getStudentsNotAffected(this.sessionStageId)
      .subscribe(
        (data: Etudiant[]) => {
          this.students = data;
        },
        (error) => {

        }
      )
  }

  private getAllServices() {
    this.busy = this.serviceService.getAll()
      .subscribe(
        (data: Service[]) => {
          this.services = data;
        }
      )
  }

  private getAllSperiodes() {
    this.busy = this.periodeService.getAllStagePeriodes(this.sessionStageId)
      .subscribe(
        (data: PeriodeStage[]) => {
          this.speriodes = data;
          this.fixSperiodes = data;
        },
        (error) => {

        }
      )
  }

  private getAllPeriodes() {
    this.busy = this.periodeService.getAllPeriodeBySessionStageId(this.sessionStageId)
      .subscribe(
        (data: Periode[]) => {
          this.periodes = data;
        },
        (error) => {

        }
      )
  }

  isEmptyFields() {
    if (!this.affectation.etudiant_id) {
      return true;
    }

    for (let i = 0; i < this.affectation.affectations.length; i++) {
      if (!this.affectation.affectations[i].service_id || !this.affectation.affectations[i].periode_stage_id) {
        return true;
      }
    }
  }

  filledServiceAndPeriode(index: number) {
    return this.affectation.affectations[index].service_id && this.affectation.affectations[index].periode_id;
  }

  filterSperiodes(periodeId) {
    let speriodes = this.fixSperiodes;

    console.log(speriodes);

    if (periodeId) {
      speriodes = speriodes.filter(
        speriode => {
          if (speriode.periode_id === periodeId) {
            return speriode
          }
        }
      )
    }
    this.speriodes = speriodes;
  }

  onChangeSelectByIndex(index: number) {
    const baseContext = this;
    const selectService = jQuery('.select-service-' + index);
    const selectPeriode = jQuery('.select-periode-' + index);
    const selectSPeriode = jQuery('.select-speriode-' + index);

    selectPeriode.on('change', function () {
      baseContext.affectation.affectations[index].periode_id = parseInt(jQuery(this).val(), 0);
      baseContext.affectation.affectations[index].periode_stage_id = 0;
      console.log(baseContext.filledServiceAndPeriode(index));
      if (baseContext.filledServiceAndPeriode(index)) {
        baseContext.filterSperiodes(baseContext.affectation.affectations[index].periode_id);
      }
      selectSPeriode.val(0).trigger('change');
    });
    selectService.on('change', function () {
      baseContext.affectation.affectations[index].service_id = parseInt(jQuery(this).val(), 0);
      if (baseContext.filledServiceAndPeriode(index)) {
        baseContext.filterSperiodes(baseContext.affectation.affectations[index].periode_id);
      }
      selectSPeriode.val(0).trigger('change');
    });

    selectSPeriode.on('change', function () {
      const speriodeId = parseInt(jQuery(this).val(), 0);
      console.log(speriodeId);
      if (!isNaN(speriodeId) && speriodeId !== 0) {
        baseContext.affectation.affectations[index].periode_stage_id = speriodeId;
        const periodeStage = baseContext.speriodes[baseContext.speriodes.map(
          item => {
            return item.periode_stage_id;
          }
        ).indexOf(speriodeId)];
        baseContext.affectation.affectations[index].speriodeLabel = "SPeriode du " + periodeStage.speriodes[0].start_date + " au" + periodeStage.speriodes[0].end_date;
      }
    });
  }

  addItemAffectation() {
    const baseContext = this;
    if (this.isEmptyFields()) {
      swal('Warning', 'Vous devez remplir tous les champs', 'warning');
      return;
    }

    this.affectation.affectations.push(new AffectationItem());
    const index = baseContext.affectation.affectations.length - 1;
    setTimeout(function () {
      Utils.initSelect('select-service-' + index);
      Utils.initSelect('select-periode-' + index);
      Utils.initSelect('select-speriode-' + index);
      baseContext.onChangeSelectByIndex(index);
    }, 20);


  }

  deleteItemAffectation() {
    this.affectation.affectations.pop();
    const baseContext = this;
    const index = baseContext.affectation.affectations.length - 1;
    setTimeout(function () {
      Utils.initSelect('select-speriode-' + index);
      baseContext.onChangeSelectByIndex(index);
    }, 5);
  }

  affect() {
    this.busy = this.sessionStageService.addAffectation(this.sessionStageId, this.affectation)
      .subscribe(
        (data) => {
          swal('Succées', 'Affectation avec succées', 'success');
        },
        (error) => {
        }
      )
  }

}
