import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExterneEvaluationComponent} from "./externe-evaluation/externe-evaluation.component";

// Layouts
export const routes: Routes = [
  {
    path: 'externe',
    component: ExterneEvaluationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageEvaluationRouting {
}
