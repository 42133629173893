import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Service} from "../../shared/models/Service";
import {Periode} from "../../shared/models/Periode";
import {Evaluation} from "../../shared/models/Evaluation";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../shared/utils";
import {ServiceService} from "../../shared/services/service.service";
import {PeriodeService} from "../../shared/services/periode.service";
import {PeriodeStage} from "../../shared/models/PeriodeStage";
import {ResultGroupe} from "../../shared/models/ResultGroupe";
import {Enseignant} from "../../shared/models/Enseignant";
import {CoordinatorService} from "../../shared/services/coordinator.service";
import {S_Periode} from "../../shared/models/S_Periode";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-tableau-board',
  templateUrl: './tableau-board.component.html',
  styleUrls: ['./tableau-board.component.css']
})
export class TableauBoardComponent implements OnInit {

  busy: Subscription;

  services: Service[] = [];


  periodes: Periode[] = [];
  periodesstage: PeriodeStage[] = [];
  fixPeriodeStage: PeriodeStage[] = [];

  evaluations: Evaluation[] = [];
  fixEvaluations: Evaluation[] = [];

  sessionStageId: number;

  selectedEvaluation: Evaluation = new Evaluation();

  coordinateurs: Enseignant[] = [];

  selectPeriodeStage: PeriodeStage = new PeriodeStage();


  constructor(private evaluationService: EvaluationService, private route: ActivatedRoute,
              private serviceService: ServiceService,
              private periodeService: PeriodeService,
              private coordinateurService: CoordinatorService,
              private router: Router) {
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'));
  }

  ngOnInit() {

    Utils.initSelect('select-service');
    Utils.initSelect('select-periode');
    Utils.initSelect('select-periode-stage');
    Utils.initSelect('select-service-chang');
    Utils.initSelect('select-week');

    this.getAllServices();
    this.getAllPeriodes();
    this.getAllStagePeriodes();

    this.getAllCoordinateurs();

    this.onChangeFilter();
  }

  private getAllCoordinateurs() {
    this.busy = this.coordinateurService.getAllCoordinateurs()
      .subscribe(
        (data: Enseignant[]) => {
          this.coordinateurs = data;
        },
        (error) => {

        }
      )
  }

  private getAllServices() {
    this.busy = this.serviceService.getAll()
      .subscribe(
        (data: Service[]) => {
          this.services = data;
        }
      )
  }


  private getAllEvaluations() {
    this.busy = this.evaluationService.getAllEvaluationBySessionStageId(this.sessionStageId)
      .subscribe(
        (data: Evaluation[]) => {
          this.evaluations = data;
          this.fixEvaluations = data;
          Utils.initializeDataTables('list-stages', 20, 7);
        },
        (error) => {

        }
      )
  }

  private getAllStagePeriodes() {
    this.busy = this.periodeService.getAllStagePeriodes(this.sessionStageId)
      .subscribe(
        (data: PeriodeStage[]) => {
          this.periodesstage = data;
          this.fixPeriodeStage = data;
        },
        (error) => {

        }
      )
  }

  private getAllPeriodes() {
    this.busy = this.periodeService.getAllPeriodeBySessionStageId(this.sessionStageId)
      .subscribe(
        (data: Periode[]) => {
          this.periodes = data;
          const periodeId = Utils.getCurrentPeriodeId(this.periodes);
          Utils.setValuesjQueryCmp('select-periode', periodeId, 20);
        },
        (error) => {
        }
      )
  }

  public affectNoteAuto() {
    const baseContext = this;
    console.log(this.selectPeriodeStage);
    if (this.selectPeriodeStage.speriodes.length > 0) {
      const lastSperiode: S_Periode = this.selectPeriodeStage.speriodes[this.selectPeriodeStage.speriodes.length - 1];
      if (Utils.convertRealDate(lastSperiode.end_date) < new Date()) {

        swal({
          title: 'Attention !',
          text: 'Êtes-vous sûrs de vouloir mettre une évaluation par défault',
          icon: 'warning',
          dangerMode: true,
          buttons: {
            cancel: {
              text: 'Non annuler',
              value: null,
              visible: true
            },
            confirm: {
              text: 'Oui',
              vaule: true,
              visible: true
            }
          }

        })
          .then((willDelete) => {
            if (willDelete) {

              baseContext.evaluationService
                .submitDefaultNotes(baseContext.selectPeriodeStage.periode_stage_id)
                .subscribe(
                  (data) => {
                    swal("Succées", "Opération effectuée avec succès", "success");
                  },
                  (error) => {

                  }
                )
            }
          });

        return;
      } else {
        swal("Warning", "Vous ne pouvez pas mettre des notes par défaut, vérifier la période", "warning");
        return;
      }
    }
    swal("Warning", "Merci de séléctionner une Sous Période", "warning");
  }

  private onChangeFilter() {
    const selectService = jQuery('.select-service');
    const selectPeriode = jQuery('.select-periode');
    const selectPeriodeStage = jQuery('.select-periode-stage');
    const selectWeek = jQuery('.select-week');

    const baseContext = this;
    selectService.on("change", function () {
      const periodeId = parseInt(selectPeriode.val(), 0);
      baseContext.getEvaluationByCriterias(baseContext.sessionStageId, parseInt(jQuery(this).val(), 0), periodeId, 0);
      selectPeriodeStage.val(0).trigger('change');
    });

    selectPeriode.on("change", function () {
      const serviceId = parseInt(selectService.val(), 0);
      baseContext.getEvaluationByCriterias(baseContext.sessionStageId, serviceId, parseInt(jQuery(this).val(), 0), 0);
      selectPeriodeStage.val(0).trigger('change');
      const weekId = parseInt(selectWeek.val(), 0);
      baseContext.filterSperiodes(weekId, parseInt(jQuery(this).val(), 0));
      baseContext.selectPeriodeStage = new PeriodeStage();
    });

    selectWeek.on("change", function () {
      const periodeId = parseInt(selectPeriode.val(), 0);
      baseContext.filterSperiodes(parseInt(jQuery(this).val(), 0), periodeId);
    });

    selectPeriodeStage.on("change", function () {
      const serviceId = parseInt(selectService.val(), 0);
      const periodeId = parseInt(selectPeriode.val(), 0);
      if (parseInt(jQuery(this).val(), 0) !== 0) {
        baseContext.getEvaluationByCriterias(baseContext.sessionStageId,
          serviceId,
          periodeId,
          parseInt(jQuery(this).val(), 0));

        baseContext.selectPeriodeStage = Utils.getElementFromKey(baseContext.periodesstage, 'periode_stage_id', parseInt(jQuery(this).val(), 0));
      }

    });
  }

  filterSperiodes(weekNumber, periodeId) {
    let periodesstage = this.fixPeriodeStage;
    if (weekNumber) {
      periodesstage = periodesstage.filter(
        periodeStage => {
          if (periodeStage.week_number === weekNumber) {
            return periodeStage
          }
        }
      );
    }
    if (periodeId) {
      periodesstage = periodesstage.filter(
        periodeStage => {
          if (periodeStage.periode_id === periodeId) {
            return periodeStage
          }
        }
      );
    }
    this.periodesstage = periodesstage;
  }

  filterServices(serviceId, periodeId, speriodeId) {
    let evaluations = this.fixEvaluations;
    if (serviceId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.service_id === serviceId) {
            return evaluation;
          }
        }
      )
    }
    if (periodeId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.speriode.periode_id === periodeId) {
            return evaluation;
          }
        }
      )
    }
    if (speriodeId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.s_periode_id === speriodeId) {
            return evaluation;
          }
        }
      )
    }
    this.evaluations = evaluations;
  }

  private getEvaluationByCriterias(sessionStageId: number, serviceId: number, periodeId: number, periodeStageId: number) {
    this.busy = this.evaluationService.getEvaluationsByCriterias(sessionStageId, serviceId, periodeId, periodeStageId)
      .subscribe(
        (dataEvaluation: Evaluation[]) => {
          this.evaluations = dataEvaluation;
          Utils.initializeDataTables('list-stages', 20, 7);
        },
        (error) => {

        }
      );
  }

  changeService(index: number) {
    this.selectedEvaluation = this.evaluations[index];
    jQuery('.modal_service_changement').modal();
  }

  confirmChangeService() {

    if (!this.selectedEvaluation.evaluation_id || !this.selectedEvaluation.enseignant_id) {
      return;
    }

    jQuery('.modal_service_changement').modal('hide');
    this.busy = this.evaluationService.changeCoordinatorForStudent(this.selectedEvaluation.evaluation_id, this.selectedEvaluation.coordinateur_id, this.selectedEvaluation.etudiant.niveau_id)
      .subscribe(
        (data) => {
          swal('Succées', 'Changement effectué avec succées', 'success');
        },
        (error) => {

        }
      )
  }

  private changeNoteEcos(resultGroup: ResultGroupe) {
    const baseContext = this;
    if (resultGroup.noteEcosEditable) {


      if (resultGroup.note_ecos <= 0 || resultGroup.note_ecos > 20) {
        swal("Warning", "La note ecos est comprise entre 0 et 20", "warning");
        return;
      }


      swal({
        title: 'Attention !',
        text: 'Êtes-vous sûrs de vouloir modifier la note ecos ?',
        icon: 'warning',
        dangerMode: true,
        buttons: {
          cancel: {
            text: 'Non annuler',
            value: null,
            visible: true
          },
          confirm: {
            text: 'Oui Modifier',
            vaule: true,
            visible: true
          }
        }

      })
        .then((willDelete) => {
          if (willDelete) {

            baseContext.busy = baseContext.evaluationService.modifyEcosNote(resultGroup.result_groupe_id, resultGroup.note_ecos)
              .subscribe(
                (data) => {
                  swal('Succeés', 'Note est modifé avec succées', 'success');
                  resultGroup.noteEcosEditable = !resultGroup.noteEcosEditable;
                }
              );
          }
        });
    } else {
      resultGroup.noteEcosEditable = !resultGroup.noteEcosEditable;
    }


  }

  editEvaluation(evaluation_id: number) {
    this.router.navigate(["/session-stage/" + this.sessionStageId + "/editEvaluation/" + evaluation_id]);
  }
}
