import {Component, OnInit} from '@angular/core';
import {Niveau} from "../../shared/models/Niveau";
import {SharedService} from "../../shared/services/shared.service";
import {Subscription} from "rxjs";
import {StudentsService} from "../../shared/services/students.service";
import {Etudiant} from "../../shared/models/Etudiant";
import {Utils} from "../../shared/utils";

declare var swal: any;

@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.css']
})
export class ListStudentsComponent implements OnInit {

  levels: Niveau[] = [];

  students: Etudiant[] = [];
  fixStudents: Etudiant[] = [];

  busy: Subscription;

  etats: any[] = [
    {
      id: -1,
      etat: 'Tous'
    },
    {
      id: 1,
      etat: 'Visible'
    },
    {
      id: 0,
      etat: 'Invisible'
    }
  ];

  constructor(private sharedService: SharedService, private studentServices: StudentsService) {
  }

  ngOnInit() {
    this.getAllLevels();
  }

  onChangeEtat(etatId) {
    let tmpStudents = this.fixStudents;

    console.log(etatId);
    if (parseInt(etatId, 0) !== -1) {
      tmpStudents = tmpStudents.filter(
        student => {
          if (student.visible === parseInt(etatId, 0)) {
            return student;
          }
        }
      )
      console.log(tmpStudents);
    }
    this.students = tmpStudents;
    Utils.initializeDataTables('list-students', 10);
  }

  getAllLevels() {
    this.busy = this.sharedService.getNiveaux()
      .subscribe(
        (data: Niveau[]) => {
          this.levels = data;
        }
      )
  }

  onChangeLevel(levelId) {
    this.getStudentsByLevel(levelId);
  }

  getStudentsByLevel(levelId) {
    this.busy = this.studentServices.getStudentsByLevel(levelId)
      .subscribe(
        (data: Etudiant[]) => {
          this.students = data;
          this.fixStudents = data;
          Utils.initializeDataTables('list-students', 10);
        }
      )
  }

  ignoreStudent(index, studentId) {
    const baseContext = this;
    swal({
      title: 'Attention !',
      text: 'Êtes-vous sûrs de vouloir faire ca ? ',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Non annuler',
          value: null,
          visible: true
        },
        confirm: {
          text: 'Oui !',
          vaule: true,
          visible: true
        }
      }

    })
      .then((willDelete) => {
        if (willDelete) {
          const status = baseContext.students[index].visible === 1 ? 0 : 1;
          baseContext.busy = baseContext.studentServices.ignoreStudents(studentId, status)
            .subscribe(
              (data) => {
                // this.students.splice(index, 1);

                baseContext.students[index].visible = status;
                Utils.initializeDataTables('list-students', 10);
                swal('Succeés', 'Etudiant supprimée avec suucées', 'success');
              }
            );
        }
      });
  }

}
