import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AffectSummerRequest, ExcelDataSummer} from "../../shared/models/affect-summer.request";
import {SummerAffectService} from "../../shared/services/summer-affect.service";
import {Periode} from "../../shared/models/Periode";
import {Utils} from "../../shared/utils";
import * as XLSX from "xlsx";
import {ActivatedRoute} from "@angular/router";
import {Etudiant} from "../../shared/models/Etudiant";
import {Service} from "../../shared/models/Service";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-affect-summer',
  templateUrl: './affect-summer.component.html',
  styleUrls: ['./affect-summer.component.css']
})
export class AffectSummerComponent implements OnInit {

  busy: Subscription;

  sessionId: number;
  affectRequest: AffectSummerRequest;
  students: ExcelDataSummer[] = [];

  studentsError: ExcelDataSummer[] = [];
  servicesError: string[] = [];

  constructor(private summerAffectService: SummerAffectService,
              private route: ActivatedRoute) {
    this.affectRequest = new AffectSummerRequest();
  }

  ngOnInit() {
    this.sessionId = parseInt(this.route.snapshot.paramMap.get('sessionStageId'), 0);
    this.getSummerPeriodBySessionId(this.sessionId);
    Utils.initFileInput('file-import-data');
  }

  private getSummerPeriodBySessionId(sessionId: number) {
    this.busy = this.summerAffectService.getSummerPeriodBySessionId(sessionId)
      .subscribe(
        (data: Periode) => {
          this.affectRequest.start_date = data.start_date;
          this.affectRequest.end_date = data.end_date;
        },
        (error) => {

        }
      )
  }


  public onFileChange(evt: any) {
    console.log("Change !!!");
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      console.log("On load !!");
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.students = XLSX.utils.sheet_to_json(ws, {raw: true});

      // let data = Utils.groupBy(this.students, 'service');

      var data: any = Utils.groupByM(this.students, function (item) {
        return [item.service];
      });
      for (let i = 0; i < data.length; i++) {
        this.affectRequest.students.push({
          service: data[i][0].service,
          students: []
        });
        for (let j = 0; j < data[i].length; j++) {
          this.affectRequest.students[i].students.push({
            cin: data[i][j].cin,
            first_name: data[i][j].first_name,
            last_name: data[i][j].last_name
          })
        }
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }


  isEmptyData() {
    return !this.affectRequest.start_date || !this.affectRequest.end_date || this.affectRequest.start_date.length === 0;
  }

  affectSummer() {
    // this.submitted = true;
    console.log(this.affectRequest);
    if (this.isEmptyData()) {
      swal('Warning', 'remplir tous les champs', 'warning');
      return;
    }

    this.busy = this.summerAffectService.affectSummerBySessionId(this.sessionId, this.affectRequest)
      .subscribe(
        (data: any) => {
          swal('Succées', 'Students affected', 'success');

          this.studentsError = data.students;
          this.servicesError = data.services;
          // EXCEL GENERATED DETAIL IMPORTATION
        },
        (error) => {
          swal('Erreur', 'Une erreur est survenu', 'error');
        }
      )
  }
}
