import {Component, OnInit} from '@angular/core';
import {Periode} from "../../shared/models/Periode";
import {Groupe} from "../../shared/models/Groupe";
import {Etudiant} from "../../shared/models/Etudiant";
import {Subscription} from "rxjs";
import {Evaluation} from "../../shared/models/Evaluation";
import {ResultGroupe} from "../../shared/models/ResultGroupe";
import {SessionStageService} from "../../shared/services/session-stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CompetanceClinique} from "../../shared/models/CompetanceClinique";
import {EvaluationCClinique} from "../../shared/models/EvaluationCClinique";
import {CriterePortfolio} from "../../shared/models/CriterePortfolio";
import {EvaluationPortfolio} from "../../shared/models/EvaluationPortfolio";


@Component({
  selector: 'app-details-evaluation-jury',
  templateUrl: './details-evaluation-jury.component.html',
  styleUrls: ['./details-evaluation-jury.component.css']
})
export class DetailsEvaluationJuryComponent implements OnInit {
  selectedEtudiant: Etudiant;
  selectedGroupe: Groupe;
  selectedPeriode: Periode;
  selectedEtudiantIndex: number;
  student_aliases: string[];
  busy: Subscription;
  periodesNumber: number;
  array_rows: Array_Row[] = [];
  no_speriodes_needed: number;
  evaluations: Evaluation[] = [];
  resultGroupes: ResultGroupe[] = [];
  context: any;
  sessionStageId: number;
  selectedPeriodeId: number;
  selectedEtudiantId: number;
  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];
  evalsJury: any[] = [];

  constructor(private sessionStageService: SessionStageService,
              private router: Router,
              private route: ActivatedRoute) {
    this.sessionStageId = parseInt(this.route.snapshot.paramMap.get('session_stage_id'), 10);
    this.context = this.sessionStageService.context;
    if (!this.context) {
      this.router.navigateByUrl('/manage-jury/list-results/' + this.sessionStageId);
    } else {
      console.log(this.context);
      this.selectedEtudiant = this.context["selectedEtudiant"];
      this.selectedEtudiantId = this.context["selectedEtudiantId"];
      this.selectedGroupe = this.context["selectedGroupe"];
      this.resultGroupes = this.context["resultGroupes"];
      this.selectedPeriodeId = this.context["selectedPeriodeId"];
      this.selectedPeriode = this.context["selectedPeriode"];
      this.student_aliases = this.context["student_aliases"];
      this.periodesNumber = Number(this.selectedPeriode.label.split(" ")[1]);
      this.array_rows = this.context["array_rows"];
      this.criteresEcos = this.context["criteresEcos"];
      this.criteresPortfolio = this.context["criteresPortfolio"];
      this.selectedEtudiantIndex = this.context["selectedEtudiantIndex"];
      this.getAllResultGroupesByStudentIdAndGroupeId(this.selectedGroupe.groupe_id,
        this.selectedEtudiantId);
    }
  }

  ngOnInit() {
  }


  getValidationPortfolio(evaluationPortfolio: EvaluationPortfolio, criterePortfolioId: number) {
    if (!evaluationPortfolio) {
      return null;
    }

    const indexValidation = evaluationPortfolio.validations.map(
      item => {
        return item.critere_portfolio_id;
      }
    ).indexOf(criterePortfolioId);

    if (indexValidation === -1) {
      return null;
    }

    return evaluationPortfolio.validations[indexValidation];
  }

  calculateNotes() {
    console.log(this.evaluations);


    this.criteresPortfolio.forEach(
      critere => {
        critere.validations = [];
        this.evaluations.forEach(
          evaluation => {
            critere.validations.push(this.getValidationPortfolio(evaluation.evaluation_portfolio, critere.critere_portfolio_id));
          }
        )
      }
    );

    console.log(this.criteresPortfolio);
    this.criteresEcos.forEach(
      critere => {
        critere.notes = [];
        this.evaluations.forEach(
          evaluation => {
            critere.notes.push(this.getValueByEvaluation(evaluation.ecos_evaluation, critere.competance_clinique_id));
          }
        )
      }
    )
  }

  getValueByEvaluation(evaluationEcos: EvaluationCClinique, competance_clinique_id: number) {
    if (!evaluationEcos)
      return -1;

    const indexCompetance = evaluationEcos.validations.map(item => {
      return item.competance_clinique_id
    }).indexOf(competance_clinique_id);

    return indexCompetance !== -1 ? evaluationEcos.validations[indexCompetance].value : -1;
  }

  getAllResultGroupesByStudentIdAndGroupeId(groupe_id: number, etudiant_id: number) {
    this.sessionStageService.getResultGroupesByStudentByIdAndGroupe(groupe_id, etudiant_id)
      .subscribe((data) => {
        const resultGroupes = data["resultGroups"];
        console.log(data);
        const evaluations = data["evaluations"];
        this.evaluations = evaluations;
        this.calculateNotes();
        for (const r of resultGroupes) {
          if (!r["evaluations"]) {
            r["evaluations"] = [];
          }
        }
        for (const e of evaluations) {
          for (const r of resultGroupes) {
            if (e["periode_stage"]["periode_id"] === r["periode_id"]) {
              r["evaluations"].push(e);
            }
          }
        }
        this.selectedGroupe.speriode_labels = [];
        const baseContext = this;
        // this.resultGroupes.forEach((rg,indexr)=>{
        baseContext.no_speriodes_needed = 0;
        let x = 1;
        baseContext.selectedGroupe.session_stage.periodes.slice(0, baseContext.periodesNumber).forEach((periode, index) => {
          periode.no_speriodes = resultGroupes[index].evaluations.length;
          baseContext.no_speriodes_needed += periode.no_speriodes;
          for (let j = 1; j <= periode.no_speriodes; j += 1) {

            this.selectedGroupe.speriode_labels.push({
              value: "Eval " + x,
              hover: this.evaluations[x - 1].service ? this.evaluations[x - 1].service.label : ""
            });

            x += 1;
          }
        });
        const arr = [];
        const criteres_count = this.array_rows.length;
        this.resultGroupes = resultGroupes;
        // Here is the initialization of the evaluation criteres of jury
        this.array_rows.forEach((r, index) => {
          r.values = [];
          r.eval_jury = baseContext.resultGroupes[baseContext.periodesNumber - 1].evaluations_criteres[index] ?
            baseContext.resultGroupes[baseContext.periodesNumber - 1].evaluations_criteres[index].value : 0;
        });
        this.resultGroupes.forEach((rg, index) => {
          rg.evaluations.forEach((evaluation, ind) => {
              for (let i = 0; i < criteres_count; i += 1) {
                baseContext.array_rows[i].values.push(
                  evaluation.evaluations_criteres[i] && index < baseContext.periodesNumber ?
                    evaluation.evaluations_criteres[i].value : " ");
              }
              baseContext.evalsJury.push(false);
            }
          );
        });
        console.log(baseContext.evalsJury);
      })
  }
}

class Array_Row {
  label_critere = "";
  critere_id = 0;
  ponderation = 0;
  values = [];
  eval_jury: number;
}
