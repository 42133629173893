import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Critere} from "../../shared/models/Critere";
import {EvaluationService} from "../../shared/services/evaluation.service";
import {Niveau} from "../../shared/models/Niveau";
import {SharedService} from "../../shared/services/shared.service";
import {Utils} from "../../shared/utils";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-externe-evaluation',
  templateUrl: './externe-evaluation.component.html',
  styleUrls: ['./externe-evaluation.component.css']
})
export class ExterneEvaluationComponent implements OnInit {

  busy: Subscription;

  criteres: Critere [] = [];

  levels: Niveau[] = [];
  selectedLevel: number = 0;

  constructor(private evaluationService: EvaluationService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    Utils.initSelect('select-level');
    this.getAllCriteres();
    this.getAllLevels();
    this.onChangeLevel();
  }

  onChangeLevel() {
    const baseContext = this;
    const selectLevel = jQuery('.select-level');

    selectLevel.on('change', function () {
      const levelId = parseInt(jQuery(this).val(), 0);
      baseContext.selectedLevel = levelId;
      baseContext.getCriteresByLevel(levelId);
    });
  }

  getCriteresByLevel(levelId: number) {

    this.criteres.forEach(
      critere => {
        const indexLevel = critere.ponderation_levels.map(
          ponderationLevel => {
            return ponderationLevel.level_id;
          }
        ).indexOf(levelId);
        critere.ponderation = indexLevel !== -1 ? critere.ponderation_levels[indexLevel].ponderation : null;
      }
    )
  }

  getAllCriteres() {
    this.busy = this.evaluationService.getAllCriteres()
      .subscribe(
        (data: Critere[]) => {
          this.criteres = data;
        },
        (error) => {

        }
      )
  }

  getPonderations() {
    let sum = 0;
    for (let i = 0; i < this.criteres.length; i++) {
      if (this.criteres[i].ponderation)
        sum += parseInt(this.criteres[i].ponderation + "", 0);
    }
    return sum;
  }

  isPonderationAble() {
    return this.getPonderations() === 100;
  }

  validEvaluation() {
    console.log(this.criteres);
    this.busy = this.evaluationService.changePonderationCriteres(this.selectedLevel, this.criteres)
      .subscribe(
        (data) => {
          swal('Succées', 'Ponderation modifiée avec succées', 'success');
        }
      )
  }

  private getAllLevels() {
    this.busy = this.sharedService.getNiveaux()
      .subscribe(
        (data: Niveau[]) => {
          this.levels = data;
        }
      )
  }
}
