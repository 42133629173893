import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable()
export class SharedService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }

  getNiveaux() {
    const url = environment.baseUrl + "/shared/niveau";

    return this.http.get(url);
  }

  getGroupesByNiveauId(niveau_id: number) {
    const url = environment.baseUrl + "/shared/groupes/byNiveau/" + niveau_id;

    return this.http.get(url);
  }

  getServices() {
    const url = environment.baseUrl + "/shared/service";

    return this.http.get(url);
  }

  getServiceById(service_id) {
    const url = environment.baseUrl + "/shared/service/byId/" + service_id;

    return this.http.get(url);
  }

  getAllEnseignant() {
    const url = environment.baseUrl + "/teacher";

    return this.http.get(url);
  }

  getAllTypes() {
    const url = environment.baseUrl + "/stage";

    return this.http.get(url);
  }

  getGroupesBySessionId(id: any) {
    const url = environment.baseUrl + "/public/session-stage/" + id + "/groupes";

    return this.http.get(url);
  }

  affecterJury(selectedEnseignantId: number, selectedGroupeId: number) {
    const url = environment.baseUrl + "/shared/groupes/affectJury/" + selectedEnseignantId + "/" + selectedGroupeId;

    return this.http.get(url);
  }

  //
  // getGroupesWhereJuryAffected() {
  //   const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read(Config.tokenAdminKey));
  //   const url = Config.baseShared + "/groupes/whereJuryAffected";
  //   return this.http.get(url, {
  //     headers: headers
  //   });
  // }
  getJurysGroupesWhereJuryAffected() {
    const url = environment.baseUrl + "/shared/groupes/whereJuryAffected";
    return this.http.get(url);
  }

  resetJury(jury_groupe_id: number) {
    const url = environment.baseUrl + "/shared/groupes/resetJury/" + jury_groupe_id;

    return this.http.delete(url);

  }

  getStudentsByLevelId(level_id: any) {
    const url = environment.baseUrl + "/public/student/byLevelId/" + level_id;
    return this.http.get<any>(url);
  }

  getNiveauById(niveau_id: number) {
    const url = environment.baseUrl + "/shared/niveau/byId/" + niveau_id;

    return this.http.get(url);
  }
}
