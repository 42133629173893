import {Service} from "./Service";
import {ResultInterne} from "./ResultInterne";
import {Periode} from "./Periode";

export class ResultServiceInterne {

  public result_service_interne_id: number;
  public decision: number;
  public periode_id: number;
  public service_id: number;
  public result_interne_id: number;
  public periode: Periode;
  public service: Service;
  public resultInterne: ResultInterne;
  /* Lié au UI */
  noteEcosEditable: boolean;


}
